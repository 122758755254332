import AsanaIcon from 'assets/connectors/asana.svg';
import FreshDeskIcon from 'assets/connectors/freshdesk.svg';
import TrelloIcon from 'assets/connectors/trello.svg';
import GoogleDriveIcon from 'assets/connectors/google-drive.svg';
import DropBoxIcon from 'assets/connectors/dropbox.svg';
import OneDriveIcon from 'assets/connectors/one-drive.svg';
import BoxIcon from 'assets/connectors/box.svg';
import SharePointIcon from 'assets/connectors/sharepoint.svg';
import NotionIcon from 'assets/connectors/notion.svg';
import ConfluenceIcon from 'assets/connectors/confluence.svg';
import SlackIcon from 'assets/connectors/slack.svg';
import TeamsIcon from 'assets/connectors/teams.svg';
import ZohoClickIcon from 'assets/connectors/zoho-cliq.svg';
import FacebookWorkplaceIcon from 'assets/connectors/facebook-workplace.svg';
import AzureDevOpsIcon from 'assets/connectors/azure.svg';
import BasecampIcon from 'assets/connectors/basecamp.svg';
import AirtableIcon from 'assets/connectors/airtable.svg';
import ClickupIcon from 'assets/connectors/clickup.svg';
import GorgiasIcon from 'assets/connectors/gorgias.svg';
import LinearIcon from 'assets/connectors/linear.svg';
import JiraIcon from 'assets/connectors/jira.svg';
import HelpScoutIcon from 'assets/connectors/help-scout.svg';
import MondayIcon from 'assets/connectors/monday.svg';
import FreshsalesIcon from 'assets/connectors/freshsales.svg';
import SalesforceIcon from 'assets/connectors/salesforce.svg';
import ZohoCRMIcon from 'assets/connectors/zoho-crm.svg';
import CopperIcon from 'assets/connectors/copper.svg';
import CloseIcon from 'assets/connectors/close.svg';
import HubSpotIcon from 'assets/connectors/hubspot.svg';
import KekaIcon from 'assets/connectors/keka.svg';
import BobIcon from 'assets/connectors/bob.svg';
import HRCloudIcon from 'assets/connectors/hr-cloud.svg';
import BambooHRIcon from 'assets/connectors/bamboo-hr.svg';
import HumaansIcon from 'assets/connectors/humaans.svg';
import ZohoPeopleIcon from 'assets/connectors/zoho-people.svg';
import RazorpayIcon from 'assets/connectors/razorpay.svg';
import GreytHRIcon from 'assets/connectors/greythr.svg';
import BreezyIcon from 'assets/connectors/breezy.svg';
import TalentLyftIcon from 'assets/connectors/talent-lyft.svg';
import LeverIcon from 'assets/connectors/lever.svg';
import PowerBIIcon from 'assets/connectors/power-bi.svg';
import LookerIcon from 'assets/connectors/looker.svg';
import TableauIcon from 'assets/connectors/tableau.svg';
import CircleCIIcon from 'assets/connectors/circle-ci.svg';
import TravisCIIcon from 'assets/connectors/travis-ci.svg';
import PagerDutyIcon from 'assets/connectors/pager-duty.svg';
import ActiveCampaignIcon from 'assets/connectors/active-campaign.svg';
import MailchimpIcon from 'assets/connectors/mailchimp.svg';
import OutreachIcon from 'assets/connectors/outreach.svg';
import SixSenseIcon from 'assets/connectors/sixsense.svg';
import ApolloIcon from 'assets/connectors/apollo.svg';
import GongIcon from 'assets/connectors/gong.svg';
import LemlistIcon from 'assets/connectors/lemlist.svg';
import ClearbitIcon from 'assets/connectors/clearbit.svg';
import CrunchBaseIcon from 'assets/connectors/crunchbase.svg';
import ZoominfoIcon from 'assets/connectors/zoominfo.svg';
import CalendlyIcon from 'assets/connectors/calendly.svg';
import GoogleCalendarIcon from 'assets/connectors/google-calendar.svg';
import TypeformIcon from 'assets/connectors/typeform.svg';
import SurveyMonkeyIcon from 'assets/connectors/survey-monkey.svg';
import IntercomIcon from 'assets/connectors/intercom.svg';
import PipedriveIcon from 'assets/connectors/pipedrive.svg';
import ZendeskIcon from 'assets/connectors/zendesk.svg';
import InternetIcon from 'assets/connectors/internet.svg';
import GitLab from 'assets/connectors/git-lab.svg';
import Canny from 'assets/connectors/canny.svg';
import CustomIcon from 'assets/connectors/custom.svg';
import JiraServiceManagementIcon from 'assets/connectors/jira-service-management.svg';
import GoogleWorkspaceIcon from 'assets/connectors/google-workspace.svg';
import OktaIcon from 'assets/connectors/okta.svg';
import YouTrackIcon from 'assets/connectors/youtrack.svg';
import HaileyHRIcon from 'assets/connectors/hailey-hr.svg';
import SquarePayroll from 'assets/connectors/square-payroll.svg';
import BitbucketIcon from 'assets/connectors/bitbucket.svg';
import Accelo from 'assets/connectors/accelo-logo.svg';
import Coda from 'assets/connectors/code-logo.svg';
import Capsule from 'assets/connectors/capsule.svg';
import MicrosoftOutlookIcon from 'assets/connectors/microsoft-outlook.svg';
import ReadMeIcon from 'assets/connectors/readme.svg';
import TodoistIcon from 'assets/connectors/todoist.svg';

export const SETTINGS_TABS = {
  GENERAL: 'general',
  MY_ACCOUNT: 'my-account',
  SEARCH_CAPABILITIES: 'search-capabilities',
  COMPANY_ADMIN: 'company-admins',
  PERSONAS: 'personas',
  CONNECTIONS: 'connections',
  AUTO_ANSWER: 'auto-answer',
  AUTOMATIONS: 'automations',
  CUSTOM_APIS: 'custom-apis'
};

export const AUTOMATION_APPS = {
  ZAPIER: 'zapier',
  N8N: 'n8n',
  IFTTT: 'ifttt',
  PIPEDREAM: 'pipedream',
  WORKATO: 'workato',
  MAKE: 'make'
};

export const CATEGORY_TAB = {
  MY_CONNECTIONS: 'My connections',
  CATEGORY: 'CATEGORY'
};

export const CONNECTOR_CATEGORIES_LIST = {
  ATS: 'ATS',
  BUSINESS_INTELLIGENCE: 'Business Intelligence',
  CI_CD: 'CI/CD',
  COMMUNICATION: 'Communication',
  CRM: 'CRM',
  FILE_STORAGE: 'File Storage',
  HELPDESK: 'Helpdesk',
  HRIS: 'HRIS',
  INCIDENT_MANAGEMENT: 'Incident Management',
  INTERNET: 'Internet',
  KNOWLEDGE_MANAGEMENT: 'Knowledge Management',
  MARKETING: 'Marketing',
  SALES_ENABLEMENT: 'Sales Enablement',
  SALES_INTELLIGENCE_AND_DATA: 'Sales Intelligence and Data',
  SCHEDULING: 'Scheduling',
  SURVEY: 'Survey',
  TICKETING: 'Ticketing'
};

export const NON_TRUTO_CONNECTORS = ['google'];

export const ACCOUNT_BASED_CONNECTORS = [
  'copper',
  'zohocrm',
  'hubspot',
  'close',
  'salesforce',
  'pipedrive',
  'freshsales',
  'activecampaign',
  'accelo',
  'haileyhr',
  'square',
  'hrcloud',
  'hibob',
  'humaans',
  'keka',
  'zohopeople',
  'googleworkspace',
  'okta',
  'greythr',
  'bamboohr',
  'capsule'
];

export const CONNECTOR_LIST = [
  {
    icon: GoogleDriveIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.FILE_STORAGE,
    name: 'google',
    title: 'Google Drive™',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    trainingTime: '5 minutes',
    isNonTrutoConnector: true
  },
  {
    icon: DropBoxIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.FILE_STORAGE,
    name: 'dropbox',
    title: 'Dropbox - Business',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    trainingTime: '5 minutes',
    tag: 'Beta'
  },
  {
    icon: OneDriveIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.FILE_STORAGE,
    name: 'onedrive',
    title: 'OneDrive',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    trainingTime: '5 minutes'
  },
  {
    icon: BoxIcon,
    isActive: false,
    category: CONNECTOR_CATEGORIES_LIST.FILE_STORAGE,
    name: 'box',
    title: 'Box',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    trainingTime: '5 minutes'
  },
  {
    icon: SharePointIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.KNOWLEDGE_MANAGEMENT,
    name: 'sharepoint',
    title: 'SharePoint',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    trainingTime: '5 minutes',
    tag: 'Beta'
  },
  {
    icon: CustomIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.FILE_STORAGE,
    name: 'custom',
    title: 'Custom Files',
    subTitle: '.txt, .csv, .xlsx, .pptx, .docx, .pdf',
    autoConnect: true,
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    trainingTime: '5 minutes',
    isNonTrutoConnector: true
  },
  {
    icon: NotionIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.KNOWLEDGE_MANAGEMENT,
    title: 'Notion',
    name: 'notion',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    trainingTime: '30 minutes'
  },
  {
    icon: ConfluenceIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.KNOWLEDGE_MANAGEMENT,
    title: 'Confluence®',
    name: 'confluence',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    trainingTime: '5 minutes'
  },
  {
    icon: ConfluenceIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.KNOWLEDGE_MANAGEMENT,
    title: 'Confluence On Prem',
    name: 'confluenceonprem',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    trainingTime: '5 minutes',
    tag: 'Beta'
  },
  {
    icon: ReadMeIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.KNOWLEDGE_MANAGEMENT,
    title: 'ReadMe',
    name: 'readme',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    trainingTime: '5 minutes',
    tag: 'Beta'
  },
  {
    icon: SlackIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.COMMUNICATION,
    title: 'Slack',
    name: 'slack',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    autoConnect: true,
    docCount: 0,
    trainingTime: '12 hours',
    isNonTrutoConnector: true,
    installLink: `${import.meta.env.VITE_API_URL}/install`
  },
  {
    icon: TeamsIcon,
    isActive: false,
    category: CONNECTOR_CATEGORIES_LIST.COMMUNICATION,
    title: 'Microsoft Teams',
    name: 'teams',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    trainingTime: '12 hours'
  },
  {
    icon: ZohoClickIcon,
    isActive: false,
    category: CONNECTOR_CATEGORIES_LIST.COMMUNICATION,
    title: 'Zoho Cliq',
    name: 'zohocliq',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    trainingTime: '12 hours'
  },
  {
    icon: FacebookWorkplaceIcon,
    isActive: false,
    category: CONNECTOR_CATEGORIES_LIST.COMMUNICATION,
    title: 'Facebook Workplace',
    name: 'facebookworkplace',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    trainingTime: '12 hours'
  },

  {
    icon: AsanaIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.TICKETING,
    title: 'Asana',
    name: 'asana',
    isConnected: false,
    actionSupported: true,
    connectionSupported: true,
    docCount: 0,
    tag: 'Beta'
  },
  {
    icon: AzureDevOpsIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.TICKETING,
    title: 'Azure DevOps',
    name: 'azuredevops',
    isConnected: false,
    actionSupported: true,
    connectionSupported: true,
    docCount: 0
  },
  {
    icon: BasecampIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.TICKETING,
    title: 'Basecamp',
    name: 'basecamp',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    tag: 'Beta'
  },
  {
    icon: AirtableIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.TICKETING,
    title: 'Airtable',
    name: 'airtable',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    tag: 'Beta'
  },
  {
    icon: ClickupIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.TICKETING,
    title: 'ClickUp',
    name: 'clickup',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    tag: 'Beta'
  },
  {
    icon: GorgiasIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.TICKETING,
    title: 'Gorgias',
    name: 'gorgias',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    tag: 'Beta'
  },
  {
    icon: LinearIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.TICKETING,
    title: 'Linear',
    name: 'linear',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    tag: 'Beta'
  },
  {
    icon: JiraServiceManagementIcon,
    isActive: false,
    category: CONNECTOR_CATEGORIES_LIST.TICKETING,
    title: 'Jira service management',
    name: 'jiraservicemanagement',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0
  },
  {
    icon: MondayIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.TICKETING,
    title: 'monday.com',
    name: 'mondaycom',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    installLink:
      'https://auth.monday.com/oauth2/authorize?client_id=dc47f8c316ed5055c968b840d8f6b079&response_type=install',
    tag: 'Beta'
  },
  {
    icon: TrelloIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.TICKETING,
    title: 'Trello',
    name: 'trello',
    isConnected: false,
    actionSupported: true,
    connectionSupported: true,
    docCount: 0,
    tag: 'Beta'
  },
  {
    icon: TodoistIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.TICKETING,
    title: 'Todoist',
    name: 'todoist',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    tag: 'Beta'
  },
  {
    icon: JiraIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.TICKETING,
    title: 'Jira',
    name: 'jira',
    isConnected: false,
    actionSupported: true,
    connectionSupported: true,
    docCount: 0
  },
  {
    icon: YouTrackIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.TICKETING,
    title: 'YouTrack',
    name: 'youtrack',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    tag: 'Beta'
  },
  {
    icon: GitLab,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.TICKETING,
    title: 'GitLab',
    name: 'gitlab',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    tag: 'Beta'
  },
  {
    icon: Canny,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.TICKETING,
    title: 'Canny',
    name: 'canny',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    tag: 'Beta'
  },
  {
    icon: BitbucketIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.TICKETING,
    title: 'Bitbucket',
    name: 'bitbucket',
    isConnected: false,
    actionSupported: true,
    connectionSupported: true,
    docCount: 0
  },
  {
    icon: HelpScoutIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.HELPDESK,
    title: 'Help Scout - Inbox',
    name: 'helpscout',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    trainingTime: '6 hours',
    tag: 'Beta'
  },
  {
    icon: FreshDeskIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.HELPDESK,
    title: 'Freshdesk',
    name: 'freshdesk',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    trainingTime: '6 hours',
    tag: 'Beta'
  },
  {
    icon: ZendeskIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.HELPDESK,
    title: 'Zendesk',
    name: 'zendesk',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    trainingTime: '6 hours',
    tag: 'Beta'
  },
  {
    icon: ZendeskIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.KNOWLEDGE_MANAGEMENT,
    title: 'Zendesk - Help Center',
    name: 'zendesk-help-center',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    trainingTime: '6 hours',
    tag: 'Beta'
  },
  {
    icon: IntercomIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.HELPDESK,
    title: 'Intercom',
    name: 'intercom',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    trainingTime: '6 hours',
    tag: 'Beta'
  },
  {
    icon: HubSpotIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.CRM,
    title: 'HubSpot',
    name: 'hubspot',
    isConnected: false,
    actionSupported: true,
    connectionSupported: true,
    docCount: 0,
    trainingTime: '12 hours',
    tag: 'Beta'
  },
  {
    icon: ZendeskIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.CRM,
    title: 'Zendesk Sell',
    name: 'zendesksell',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    trainingTime: '12 hours'
  },
  {
    icon: PipedriveIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.CRM,
    title: 'Pipedrive',
    name: 'pipedrive',
    isConnected: false,
    actionSupported: true,
    connectionSupported: true,
    docCount: 0,
    trainingTime: '12 hours',
    tag: 'Beta'
  },
  {
    icon: CloseIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.CRM,
    title: 'Close',
    name: 'close',
    isConnected: false,
    actionSupported: true,
    connectionSupported: true,
    docCount: 0,
    trainingTime: '12 hours',
    tag: 'Beta'
  },
  {
    icon: CopperIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.CRM,
    title: 'Copper',
    name: 'copper',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    trainingTime: '12 hours',
    tag: 'Beta'
  },
  {
    icon: FreshsalesIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.CRM,
    title: 'Freshsales',
    name: 'freshsales',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    tag: 'Beta'
  },
  {
    icon: SalesforceIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.CRM,
    title: 'Salesforce',
    name: 'salesforce',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    trainingTime: '12 hours',
    tag: 'Beta'
  },
  {
    icon: ZohoCRMIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.CRM,
    title: 'Zoho CRM',
    name: 'zohocrm',
    isConnected: false,
    actionSupported: true,
    connectionSupported: true,
    docCount: 0,
    trainingTime: '12 hours',
    tag: 'Beta'
  },
  {
    icon: Capsule,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.CRM,
    title: 'Capsule',
    name: 'capsule',
    isConnected: false,
    actionSupported: true,
    connectionSupported: true,
    docCount: 0,
    trainingTime: '12 hours',
    tag: 'Beta'
  },
  {
    icon: KekaIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.HRIS,
    title: 'Keka',
    name: 'keka',
    isConnected: false,
    docCount: 0,
    tag: 'Beta',
    actionSupported: false,
    connectionSupported: true
  },
  {
    icon: BobIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.HRIS,
    title: 'HiBob',
    name: 'hibob',
    isConnected: false,
    docCount: 0,
    tag: 'Beta',
    actionSupported: false,
    connectionSupported: true
  },
  {
    icon: HRCloudIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.HRIS,
    title: 'HR Cloud',
    name: 'hrcloud',
    tag: 'Beta',
    actionSupported: false,
    connectionSupported: true
  },
  {
    icon: BambooHRIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.HRIS,
    title: 'BambooHR',
    name: 'bamboohr',
    tag: 'Beta',
    actionSupported: false,
    connectionSupported: true
  },
  {
    icon: HumaansIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.HRIS,
    title: 'Humaans',
    name: 'humaans',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    tag: 'Beta'
  },
  {
    icon: Coda,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.KNOWLEDGE_MANAGEMENT,
    title: 'Coda',
    name: 'coda',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    tag: 'Beta'
  },
  {
    icon: HelpScoutIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.KNOWLEDGE_MANAGEMENT,
    title: 'Help Scout - Docs',
    name: 'helpscoutdocs',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    trainingTime: '6 hours',
    tag: 'Beta'
  },
  {
    icon: ZohoPeopleIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.HRIS,
    title: 'Zoho People',
    name: 'zohopeople',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    tag: 'Beta'
  },
  {
    icon: GreytHRIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.HRIS,
    title: 'GreytHR',
    name: 'greythr',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    tag: 'Beta'
  },
  {
    icon: RazorpayIcon,
    isActive: false,
    category: CONNECTOR_CATEGORIES_LIST.HRIS,
    title: 'RazorpayX Payroll',
    name: 'razorpayxpayroll',
    actionSupported: false,
    connectionSupported: true
  },
  {
    icon: GoogleWorkspaceIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.HRIS,
    title: 'Google Workspace',
    name: 'googleworkspace',
    tag: 'Beta',
    actionSupported: false,
    connectionSupported: true
  },
  {
    icon: OktaIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.HRIS,
    title: 'Okta',
    name: 'okta',
    tag: 'Beta',
    actionSupported: false,
    connectionSupported: true
  },
  {
    icon: HaileyHRIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.HRIS,
    title: 'HaileyHR',
    name: 'haileyhr',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    tag: 'Beta'
  },
  {
    icon: SquarePayroll,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.HRIS,
    title: 'Square Payroll',
    name: 'square',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    tag: 'Beta'
  },
  {
    icon: BreezyIcon,
    isActive: false,
    category: CONNECTOR_CATEGORIES_LIST.ATS,
    title: 'Breezy',
    name: 'breezy',
    actionSupported: false,
    connectionSupported: true
  },
  {
    icon: TalentLyftIcon,
    isActive: false,
    category: CONNECTOR_CATEGORIES_LIST.ATS,
    title: 'TalentLyft',
    name: 'talentlyft',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0
  },
  {
    icon: LeverIcon,
    isActive: false,
    category: CONNECTOR_CATEGORIES_LIST.ATS,
    title: 'Lever',
    name: 'lever',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0
  },

  {
    icon: PowerBIIcon,
    isActive: false,
    category: CONNECTOR_CATEGORIES_LIST.BUSINESS_INTELLIGENCE,
    title: 'Power BI',
    name: 'powerbi',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0
  },
  {
    icon: LookerIcon,
    isActive: false,
    category: CONNECTOR_CATEGORIES_LIST.BUSINESS_INTELLIGENCE,
    title: 'Looker',
    name: 'looker',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0
  },
  {
    icon: TableauIcon,
    isActive: false,
    category: CONNECTOR_CATEGORIES_LIST.BUSINESS_INTELLIGENCE,
    title: 'Tableau',
    name: 'tableau',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0
  },
  {
    icon: CircleCIIcon,
    isActive: false,
    category: CONNECTOR_CATEGORIES_LIST.CI_CD,
    title: 'CircleCI',
    name: 'circleci',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0
  },
  {
    icon: TravisCIIcon,
    isActive: false,
    category: CONNECTOR_CATEGORIES_LIST.CI_CD,
    title: 'Travis CI',
    name: 'travisci',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0
  },
  {
    icon: PagerDutyIcon,
    isActive: false,
    category: CONNECTOR_CATEGORIES_LIST.INCIDENT_MANAGEMENT,
    title: 'PagerDuty',
    name: 'pagerduty',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0
  },
  {
    icon: ActiveCampaignIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.MARKETING,
    title: 'ActiveCampaign',
    name: 'activecampaign',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    tag: 'Beta'
  },
  {
    icon: MailchimpIcon,
    isActive: false,
    category: CONNECTOR_CATEGORIES_LIST.MARKETING,
    title: 'Mailchimp',
    name: 'mailchimp',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0
  },
  {
    icon: Accelo,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.MARKETING,
    title: 'Accelo',
    name: 'accelo',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    tag: 'Beta'
  },
  {
    icon: OutreachIcon,
    isActive: false,
    category: CONNECTOR_CATEGORIES_LIST.SALES_ENABLEMENT,
    title: 'Outreach',
    name: 'outreach',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0
  },
  {
    icon: SixSenseIcon,
    isActive: false,
    category: CONNECTOR_CATEGORIES_LIST.SALES_ENABLEMENT,
    title: '6sense',
    name: 'sixsense',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0
  },
  {
    icon: ApolloIcon,
    isActive: false,
    category: CONNECTOR_CATEGORIES_LIST.SALES_ENABLEMENT,
    title: 'Apollo',
    name: 'apollo',
    actionSupported: false,
    connectionSupported: true
  },
  {
    icon: GongIcon,
    isActive: false,
    category: CONNECTOR_CATEGORIES_LIST.SALES_ENABLEMENT,
    title: 'Gong',
    name: 'gong',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0
  },
  {
    icon: LemlistIcon,
    isActive: false,
    category: CONNECTOR_CATEGORIES_LIST.SALES_ENABLEMENT,
    title: 'Lemlist',
    name: 'lemlist',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0
  },
  {
    icon: ClearbitIcon,
    isActive: false,
    category: CONNECTOR_CATEGORIES_LIST.SALES_INTELLIGENCE_AND_DATA,
    title: 'Clearbit',
    name: 'clearbit',
    actionSupported: false,
    connectionSupported: true
  },
  {
    icon: CrunchBaseIcon,
    isActive: false,
    category: CONNECTOR_CATEGORIES_LIST.SALES_INTELLIGENCE_AND_DATA,
    title: 'Crunchbase',
    name: 'crunchbase',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0
  },
  {
    icon: ZoominfoIcon,
    isActive: false,
    category: CONNECTOR_CATEGORIES_LIST.SALES_INTELLIGENCE_AND_DATA,
    title: 'Zoominfo',
    name: 'zoominfo',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0
  },
  {
    icon: CalendlyIcon,
    isActive: false,
    category: CONNECTOR_CATEGORIES_LIST.SCHEDULING,
    title: 'Calendly',
    name: 'calendly',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0
  },
  {
    icon: GoogleCalendarIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.SCHEDULING,
    title: 'Google Calendar™',
    name: 'googlecalendar',
    isConnected: false,
    isActionOnly: true,
    actionSupported: true,
    connectionSupported: false,
    docCount: 0,
    trainingTime: '5 minutes'
  },
  {
    icon: TypeformIcon,
    isActive: false,
    category: CONNECTOR_CATEGORIES_LIST.SURVEY,
    title: 'Typeform',
    name: 'typeform',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0
  },
  {
    icon: SurveyMonkeyIcon,
    isActive: false,
    category: CONNECTOR_CATEGORIES_LIST.SURVEY,
    title: 'SurveyMonkey',
    name: 'surveymonkey',
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0
  },
  {
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.INTERNET,
    icon: InternetIcon,
    title: 'Web',
    subTitle: 'sitemap, web pages',
    name: 'web',
    autoConnect: true,
    isConnected: false,
    actionSupported: false,
    connectionSupported: true,
    docCount: 0,
    trainingTime: '4 hours',
    isNonTrutoConnector: true,
    tag: 'Beta'
  },
  {
    icon: MicrosoftOutlookIcon,
    isActive: true,
    category: CONNECTOR_CATEGORIES_LIST.SCHEDULING,
    title: 'Microsoft Outlook',
    name: 'outlookcalendar',
    isConnected: false,
    actionSupported: true,
    connectionSupported: false,
    docCount: 0,
    trainingTime: '5 minutes'
  }
];

export const ACTIONS_SUPPORTED_CONNECTORS = CONNECTOR_LIST.filter((conn) => conn.actionSupported);

export const CONNECTOR_CATEGORIES = Object.values(CONNECTOR_CATEGORIES_LIST).map((category) => {
  const filteredConnectors = CONNECTOR_LIST.filter(
    (conn) => conn.category === category && conn.connectionSupported
  );
  return {
    name: category,
    count: filteredConnectors.length,
    connectors: filteredConnectors
  };
});

export const CONNECTOR_CATEGORY_LIST_FOR_DROPDOWN = [
  { label: CATEGORY_TAB.MY_CONNECTIONS, value: CATEGORY_TAB.MY_CONNECTIONS },
  ...Object.values(CONNECTOR_CATEGORIES_LIST).map((category) => {
    return { label: category, value: category };
  })
];
