import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useForm, yupResolver } from '@mantine/form';
import { Button, Flex, Input, PasswordInput, TextInput } from '@mantine/core';
import Password from 'components/FormComponents/Password';
import { emailRegex } from '../../../../utils/helpers';
import styles from './styles.module.scss';

const schema = (props) => {
  const { companyArg, emailArg, passwordArg, newPasswordArg, confirmPasswordArg, nameArgs } = props;

  return Yup.object().shape({
    company: companyArg ? Yup.string().required('required*') : Yup.string(),
    email: emailArg
      ? Yup.string().matches(emailRegex, 'This email is invalid.').required('required*')
      : Yup.string(),
    firstName: nameArgs ? Yup.string().required('required*') : Yup.string(),
    lastName: nameArgs ? Yup.string().required('required*') : Yup.string(),
    password: passwordArg ? Yup.string().required('required*') : Yup.string(),
    newPassword: newPasswordArg ? Yup.string().required('required*') : Yup.string(),
    confirmPassword: confirmPasswordArg
      ? Yup.string()
          .required('required*')
          .oneOf([Yup.ref('newPassword')], 'Passwords must match')
      : Yup.string()
  });
};

const AuthForm = (props) => {
  const {
    isLoading,
    initialValues,
    ctaLabel,
    handleSubmitCTA,
    handleForgotPassword,
    header,
    footer,
    company,
    email,
    disableEmail,
    hideEmailLabel,
    name,
    password,
    newPassword,
    forgotPassword,
    confirmPassword,
    isSubmitting,
    error,
    isInviteFlow,
    isDisabled
  } = props;
  const form = useForm({
    initialValues: initialValues,
    validate: yupResolver(
      schema({
        companyArg: company,
        emailArg: email,
        passwordArg: password,
        newPasswordArg: newPassword,
        confirmPasswordArg: confirmPassword,
        nameArgs: name
      })
    )
  });

  const [isPasswordValid, setIsPasswordValid] = useState(false);

  useEffect(() => {
    if (email && form.isValid('email') && error) {
      form.setFieldError('email', error);
    }
  }, [error, form.email]);

  const handlePasswordValidityChange = (isValid) => {
    setIsPasswordValid(isValid);
  };

  const handleSubmit = async (values) => {
    await handleSubmitCTA(values);
    form.reset();
  };

  return (
    <form
      onSubmit={form.onSubmit(async (values) => await handleSubmit(values))}
      className={styles.authForm}
    >
      {!isInviteFlow && <header className={styles.header}>{header}</header>}

      {company ? (
        <TextInput
          radius={4}
          size='lg'
          classNames={{
            input: styles.company
          }}
          placeholder='Enter your company name'
          {...form.getInputProps('company')}
        />
      ) : null}

      {email ? (
        <TextInput
          radius={4}
          size='lg'
          label={hideEmailLabel ? null : 'Email'}
          disabled={disableEmail}
          id='username'
          placeholder='Enter your email'
          {...form.getInputProps('email')}
        />
      ) : null}

      {name ? (
        <Flex direction='column'>
          <Input.Label>Name</Input.Label>
          <Flex direction='row' gap={10}>
            <TextInput
              radius={4}
              size='lg'
              placeholder='First name'
              {...form.getInputProps('firstName')}
            />
            <TextInput
              radius={4}
              size='lg'
              placeholder='Last name'
              {...form.getInputProps('lastName')}
            />
          </Flex>
        </Flex>
      ) : null}

      {password ? (
        <PasswordInput
          radius={4}
          size='lg'
          label='Password'
          id='password'
          placeholder='Enter your password'
          {...form.getInputProps('password')}
        />
      ) : null}

      {forgotPassword ? (
        <p onClick={handleForgotPassword} className={styles.forgotPassword}>
          Forgot Password ?
        </p>
      ) : null}

      {newPassword ? (
        <Password
          radius={4}
          size='lg'
          label='Password'
          placeholder='Enter a new password'
          onPasswordChange={handlePasswordValidityChange}
          {...form.getInputProps('newPassword', { shouldValidate: true })}
        />
      ) : null}

      {confirmPassword ? (
        <PasswordInput
          radius={4}
          size='lg'
          label='Confirm Password'
          placeholder='Enter the new password again'
          {...form.getInputProps('confirmPassword')}
        />
      ) : null}

      <Button
        className={classNames('primaryBtn', { [styles.disabled]: isDisabled })}
        fullWidth
        type='submit'
        disabled={isDisabled || isSubmitting || (newPassword && !isPasswordValid)}
        loading={isLoading}
        id='signin'
      >
        {ctaLabel}
      </Button>

      {!isInviteFlow && <footer className={styles.footer}>{footer}</footer>}
    </form>
  );
};

AuthForm.defaultProps = {
  isLoading: false,
  company: false,
  email: false,
  disableEmail: false,
  hideEmailLabel: false,
  name: false,
  password: false,
  newPassword: false,
  forgotPassword: false,
  confirmPassword: false,
  footer: <></>,
  handleForgotPassword: () => null,
  isSubmitting: false,
  error: '',
  isInviteFlow: false,
  isDisabled: false
};

AuthForm.propTypes = {
  isLoading: PropTypes.bool,
  initialValues: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    password: PropTypes.string,
    confirmPassword: PropTypes.string
  }).isRequired,
  ctaLabel: PropTypes.string.isRequired,
  handleSubmitCTA: PropTypes.func.isRequired,
  handleForgotPassword: PropTypes.func,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  footer: PropTypes.node,
  company: PropTypes.bool,
  email: PropTypes.bool,
  disableEmail: PropTypes.bool,
  hideEmailLabel: PropTypes.bool,
  name: PropTypes.bool,
  password: PropTypes.bool,
  newPassword: PropTypes.bool,
  forgotPassword: PropTypes.bool,
  confirmPassword: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  error: PropTypes.string,
  isInviteFlow: PropTypes.bool,
  isDisabled: PropTypes.bool
};

export default AuthForm;
