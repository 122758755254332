import { useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mantine/hooks';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import {
  Modal,
  Title,
  Flex,
  Button,
  Image,
  ScrollArea,
  Text,
  Group,
  Switch,
  Tooltip
} from '@mantine/core';
import { AddSourceButtonUI } from '../../Components/AddSourceButton';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import { useAddSourcesMutation } from 'redux/services/integrations';
import FileUploadIcon from 'assets/icons/file-upload-icon.svg';
import InfoIcon from 'assets/icons/info-gray.svg';
import CloseIcon from 'assets/icons/close-icon.svg';
import { returnIcon, DOCUMENT } from 'pages/Integrations/utils';
import { validateFileUpload } from 'utils/helpers';
import { maybePluralize } from 'utils/utilities';
import styles from './styles.module.scss';

const AddCustomSources = ({ teamIdFromProps, clickCallback, useLlamaparser }) => {
  const screen767 = useMediaQuery('(max-width: 767px)');
  const { teamId } = useParams();
  const [opened, setOpened] = useState(false);
  const [files, setFiles] = useState([]);
  const [addSources, { isLoading }] = useAddSourcesMutation();
  const [usePremiumMode, setUsePremiumMode] = useState(false);
  const fileSize = { min: 0, max: 100 * 1024 * 1024, maxSizeText: '100 MB' };
  const fileTypes = ['.csv', '.docx', '.pdf', '.pptx', '.txt', '.xlsx'];

  const onUpload = async (files) => {
    for (const file of files) {
      const validationResult = validateFileUpload(file);
      if (!validationResult) {
        return; // If file is invalid, return
      }
    }
    const documents = files.map((file) => ({
      documentId: encodeURIComponent(file.name),
      documentName: encodeURIComponent(file.name),
      provider: 'custom'
    }));

    const formData = new FormData();
    files.forEach((file) => {
      const encodedFileName = encodeURIComponent(file.name);
      // Creating a new File object with the encoded name
      const renamedFile = new File([file], encodedFileName, { type: file.type });
      formData.append('files', renamedFile);
    });
    formData.append('documents', JSON.stringify(documents));
    formData.append('provider', 'custom');

    const payload = {
      body: formData,
      provider: 'custom',
      params: { teamId: teamId || teamIdFromProps, usePremiumMode }
    };
    const response = await addSources(payload);

    if (response.data) {
      setOpened(false);
      setFiles([]);
      clickCallback?.();
    }
  };

  const onDrop = (acceptedFiles) => {
    const promises = acceptedFiles.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = () => {
          const content = reader.result;
          if (content.length > fileSize.min) {
            resolve(file);
          } else {
            reject(new Error('File is empty'));
          }
        };
        reader.onerror = reject;
      });
    });

    Promise.allSettled(promises)
      .then((results) => {
        const nonEmptyFiles = results
          .filter((result) => result.status === 'fulfilled')
          .map((result) => result.value);
        if (nonEmptyFiles.length < acceptedFiles.length) {
          toast.error('File size should be greater than 0');
        } else {
          setFiles([...files, ...nonEmptyFiles]);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const handleRejectedFiles = (rejectedFiles) => {
    if (rejectedFiles && rejectedFiles[0].errors[0].code === DOCUMENT.INVALID) {
      toast.error('File is invalid');
    } else if (rejectedFiles && rejectedFiles[0].errors[0].code === DOCUMENT.LARGE_FILE) {
      toast.error(`File size should be less than ${fileSize.maxSizeText}`);
    }
  };

  return (
    <>
      <Modal
        centered
        opened={opened}
        size={630}
        radius={4}
        classNames={{ body: styles.modalContent }}
        className='mobile-modal'
        onClose={() => {
          setOpened(false);
          setFiles([]);
        }}
        title={<Title className={styles.pageTitle}>Upload file</Title>}
      >
        <Dropzone
          accept={[
            MIME_TYPES.pdf,
            MIME_TYPES.csv,
            MIME_TYPES.xlsx,
            MIME_TYPES.docx,
            MIME_TYPES.pptx,
            'text/plain' // Allow plain text files
          ]}
          maxSize={fileSize.max}
          onDrop={onDrop}
          onReject={handleRejectedFiles}
          className={classNames(styles.dropZone, {
            [styles.reduceHeight]: files.length > 0
          })}
        >
          <Group className={styles.fileUploaderContainer}>
            <Image src={FileUploadIcon} alt='file upload icon' className={styles.fileUploadIcon} />
            <div className={styles.heading}>
              Drag & drop or <span className={styles.textLink}>browse</span>
            </div>
            <div className={styles.description}>
              Supports {fileTypes.join(', ')}, under {fileSize.maxSizeText}.
            </div>
          </Group>
        </Dropzone>

        <ScrollArea type='auto' scrollbarSize={8}>
          <div className={styles.filesWrapper}>
            {files.map((file, index) => {
              const { icon, alt } = returnIcon(file.type);
              return (
                <div key={index} className={styles.container}>
                  <div className={styles.fileUploaderItem} key={index}>
                    <Image src={icon} alt={alt} h={38} w={38} />
                    <div className='font-poppins truncated-text'>{file.name}</div>
                    <Image
                      src={CloseIcon}
                      alt='close'
                      className={styles.closeIcon}
                      ml='auto'
                      onClick={() => {
                        const newFiles = files.filter((_item, i) => i !== index);
                        setFiles(newFiles);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </ScrollArea>

        <Flex mt={30} className={styles.footer} justify='space-between' align='center'>
          {files.length > 0 && (
            <Text className={styles.text}>{`${files.length} ${maybePluralize(
              files.length,
              'file'
            )}  selected`}</Text>
          )}
          <div className={styles.btnContainer}>
            {useLlamaparser && (
              <div className={styles.llamaparserOptions}>
                <Tooltip w={250} label='Use Premium mode for enhanced table data extraction.'>
                  <Image src={InfoIcon} className={styles.infoIcon} />
                </Tooltip>
                <Switch
                  size='md'
                  checked={usePremiumMode}
                  onChange={(event) => setUsePremiumMode(event.currentTarget.checked)}
                  color='#1CA555'
                  ml={5}
                  mr={20}
                />
              </div>
            )}
            <Button
              variant='outline'
              radius={4}
              color='dark'
              onClick={() => {
                setOpened(false);
                setFiles([]);
              }}
              disabled={isLoading}
              style={{ height: screen767 ? 35 : 42 }}
            >
              Cancel
            </Button>
            <Button
              className={classNames('primaryBtn', { [styles.btnDisable]: files.length === 0 })}
              onClick={() => {
                onUpload(files);
              }}
              w={100}
              loading={isLoading}
              disabled={isLoading || files.length === 0}
              ml={20}
            >
              Add
            </Button>
          </div>
        </Flex>
      </Modal>
      <AddSourceButtonUI
        handleAddSource={() => {
          setOpened(true);
        }}
      />
    </>
  );
};

AddCustomSources.defalutProps = {
  teamIdFromProps: '',
  clickCallback: () => null,
  useLlamaparser: false
};

AddCustomSources.propTypes = {
  teamIdFromProps: PropTypes.string,
  clickCallback: PropTypes.func,
  useLlamaparser: PropTypes.bool
};

export default AddCustomSources;
