import { bool, string } from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { NAVBAR_ROUTES } from 'utils/constants';

const CustomNavigate = ({ to, replace, ...rest }) => {
  const location = useLocation();
  const { user } = useSelector((state) => state.user);
  const queryParams = new URLSearchParams(location.search);
  const queryParamString = queryParams.toString();

  if (user && (!user.isAdmin && !user.isTeamAdmin)) {
    to=NAVBAR_ROUTES.ASK_CHAI;
  }
  
  const updatedTo = queryParamString ? `${to}?${queryParamString}` : to;

  return <Navigate to={updatedTo} replace={replace} {...rest} />;
};

CustomNavigate.propTypes = {
  to: string,
  replace: bool
};

export default CustomNavigate;
