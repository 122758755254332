import { Tooltip, Table, Checkbox } from '@mantine/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { stringDecoder } from 'utils/helpers';
import { formatDate } from 'utils/formatters';
import { ACCOUNT_BASED_CONNECTORS } from 'pages/ControlPanel/constants';
import { PROVIDERS } from 'utils/constants';
import styles from './styles.module.scss';

const QuestionRows = (props) => {
  const {
    rowValues,
    itemClickHandler = () => null,
    disableRowClick = false,
    handleCheckboxCta = () => null,
    selectedSources = [],
    showCheckbox = false,
    isAllSourcesSelected = false,
    checkboxTooltipLabels = []
  } = props;

  return (
    <Table.Tbody>
      {rowValues.map((row, index) => {
        return (
          <Table.Tr
            key={`rows-${index}`}
            className={classNames({
              [styles.clickableRow]: itemClickHandler && !disableRowClick
            })}
            onClick={() => itemClickHandler(row)}
          >
            {showCheckbox && (
              <Table.Td className={styles.title}>
                <Tooltip
                  disabled={!sourceIsProcessing && !isAllSourcesSelected}
                  w={390}
                  label={
                    isAllSourcesSelected && !sourceIsProcessing
                      ? checkboxTooltipLabels[0]
                      : checkboxTooltipLabels[1]
                  }
                >
                  <Checkbox
                    onClick={(e) => e.stopPropagation()}
                    onChange={() => handleCheckboxCta(row)}
                    color='primaryGreen.3'
                    radius={4}
                    checked={
                      !sourceIsProcessing &&
                      (isAllSourcesSelected || selectedSources?.includes(row.id))
                    }
                    disabled={sourceIsProcessing}
                    classNames={{
                      root: styles.checkbox,
                      input: classNames({ ['cursor-notAllowed']: isAllSourcesSelected }),
                      label: classNames(styles.selectAll, {
                        [styles.disabled]: sourceIsProcessing
                      })
                    }}
                  />
                </Tooltip>
              </Table.Td>
            )}

            <Table.Td>{formatDate(row.askedAt, 'DD MMMM, YYYY hh:mm:ss A')}</Table.Td>
            <Table.Td>{row.user?.name}</Table.Td>
            <Table.Td>{row.prompt}</Table.Td>
            <Table.Td>
              {row.completion?.training ? row.completion.training : row.completion?.ai}
            </Table.Td>
          </Table.Tr>
        );
      })}
    </Table.Tbody>
  );
};

QuestionRows.propTypes = {
  rowValues: PropTypes.array.isRequired,
  itemClickHandler: PropTypes.func,
  disableRowClick: PropTypes.bool,
  handleCheckboxCta: PropTypes.func,
  selectedSources: PropTypes.array,
  showCheckbox: PropTypes.bool,
  isAllSourcesSelected: PropTypes.bool,
  checkboxTooltipLabels: PropTypes.array
};

export default QuestionRows;
