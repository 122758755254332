import PropTypes from 'prop-types';
import { Button } from '@mantine/core';
import { ReactComponent as ListIcon } from 'assets/icons/list.svg';
import { ReactComponent as GridIcon } from 'assets/icons/grid.svg';
import { VIEW_TYPE } from 'utils/constants';
import styles from './styles.module.scss';

const ViewToggle = (props) => {
  const {
    viewType = 'list',
    setViewType,
    customWrapperStyles = '',
    pageName = '',
    setPage = () => {}
  } = props;

  return (
    <div className={customWrapperStyles || styles.toolBar}>
      <div className={styles.buttonsGroup}>
        <Button.Group className={styles.actionBtnGroup}>
          <Button
            variant='default'
            className={viewType === VIEW_TYPE.LIST && styles.activeViewTypeBtn}
            onClick={() => {
              setPage(1);
              setViewType(VIEW_TYPE.LIST);
              localStorage.setItem(
                (pageName?.length > 0 ? pageName + '.' : '') + 'viewType',
                VIEW_TYPE.LIST
              );
            }}
          >
            <ListIcon />
          </Button>
          <Button
            variant='default'
            className={viewType === VIEW_TYPE.GRID && styles.activeViewTypeBtn}
            onClick={() => {
              setPage(1);
              setViewType(VIEW_TYPE.GRID);
              localStorage.setItem(
                (pageName?.length > 0 ? pageName + '.' : '') + 'viewType',
                VIEW_TYPE.GRID
              );
            }}
          >
            <GridIcon />
          </Button>
        </Button.Group>
      </div>
    </div>
  );
};

ViewToggle.propTypes = {
  viewType: PropTypes.string,
  setViewType: PropTypes.func,
  customWrapperStyles: PropTypes.string,
  pageName: PropTypes.string,
  setPage: PropTypes.func
};

export default ViewToggle;
