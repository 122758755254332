import classNames from 'classnames';
import { useState } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  ActionIcon,
  Anchor,
  Flex,
  HoverCard,
  Text,
  TextInput,
  Title,
  Tooltip,
  UnstyledButton
} from '@mantine/core';
import integrationsApi, { useTrainSourcesMutation } from 'redux/services/integrations';
import DeleteWarningModal from 'components/Modals/DeleteWarningModal';
import Button from 'components/Button';
import { truncateName } from 'pages/Integrations/utils';
import { ReactComponent as InfoIcon } from 'assets/icons/info-blue.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as InfoGrayIcon } from 'assets/icons/info-gray.svg';
import styles from './styles.module.scss';

const Header = (props) => {
  const {
    searchValue,
    setSearchValue,
    setCount,
    setSelectedSources,
    setIsAllSelected,
    disableRetrain
  } = props;
  const url = new URL(window.location.href);
  const docId = url.searchParams.get('docId');
  const hasChildSources = url.searchParams.get('hasChildSources') === 'true';
  const sourceName = decodeURIComponent(url.searchParams.get('sourceName'));
  const { teamId } = useParams();
  const [showRetrainModal, setShowRetrainModal] = useState(false);
  const [showRecrawlModal, setShowRecrawlModal] = useState(false);
  const [trainSources] = useTrainSourcesMutation();
  const dispatch = useDispatch();

  const handleRetrain = async () => {
    const response = await trainSources({
      documentMongoIds: [],
      retrain: 'now',
      teamId,
      trainType: 'Force refresh by user',
      parentMongoId: docId,
      provider: 'web',
      includeAllSubDocs: true
    });
    if (response?.data?.ok) {
      toast.success(response.data.message);
      setShowRetrainModal(false);
      setCount({ active: null });
      setSelectedSources([]);
      setIsAllSelected(false);
      dispatch(integrationsApi.util.invalidateTags(['SubDocuments']));
    }
  };

  const handleRecrawl = async () => {
    const response = await trainSources({
      documentMongoIds: [],
      retrain: 'now',
      teamId,
      trainType: 'Recrawl',
      parentMongoId: docId,
      provider: 'web',
      includeAllSubDocs: false
    });
    if (response?.data?.ok) {
      toast.success(response.data.message);
      setShowRecrawlModal(false);
      setCount({ active: null });
      setSelectedSources([]);
      setIsAllSelected(false);
      dispatch(integrationsApi.util.invalidateTags(['SubDocuments']));
    }
  };

  return (
    <>
      {showRetrainModal && (
        <DeleteWarningModal
          open={true}
          buttonColor='#1CA555'
          removeText='Confirm'
          header='Are you sure want to retrain Chai on this source?'
          title=''
          body="When Chai retrains on a web page, it looks for updated content and absorbs it. This ensures he's up to date."
          handleCancel={() => setShowRetrainModal(false)}
          onClose={() => setShowRetrainModal(false)}
          handleDelete={handleRetrain}
        />
      )}
      {showRecrawlModal && (
        <DeleteWarningModal
          open={true}
          buttonColor='#1CA555'
          removeText='Confirm'
          header='Are you sure you want to recrawl this source?'
          title=''
          body={
            <>
              When you recrawl the source URL, Chai visits it, looks for any new web pages on the
              same domain and adds them.
              <br />
              If previously included web pages were removed from the source URL, Chai will delete
              them and their underlying content from this wiki.
            </>
          }
          handleCancel={() => setShowRecrawlModal(false)}
          onClose={() => setShowRecrawlModal(false)}
          handleDelete={handleRecrawl}
        />
      )}
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <Tooltip
            label={sourceName}
            disabled={sourceName.length < 100}
            maw={1000}
            classNames={{ tooltip: styles.tooltipText }}
          >
            <div className={styles.sourceName}>
              Source: <span>{truncateName(sourceName, 100)}</span>
            </div>
          </Tooltip>
          <div className={styles.actions}>
            <HoverCard classNames={{ dropdown: styles.popoverDropdown }} withArrow shadow='md'>
              <HoverCard.Target>
                <UnstyledButton variant='subtle' className={styles.popoverIcon}>
                  <InfoIcon />
                </UnstyledButton>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <Flex direction='column' gap='10px'>
                  <div>
                    <Title className='font-poppins' size='12px' lh='22px'>
                      Retrain
                    </Title>
                    <Text className='font-poppins' size='12px' w='309px' lh='22px'>
                      Retrain Chai on web pages included in your wiki for training to ensure Chai is
                      up to date. When you retrain, you ignore any new web pages added to or old web
                      pages removed from your source web page.
                    </Text>
                  </div>
                  <div>
                    <Title className='font-poppins' size='12px' lh='22px'>
                      Recrawl
                    </Title>
                    <Text className='font-poppins' size='12px' w='309px' lh='22px'>
                      Recrawl in case new web pages are added to or old web pages are removed from
                      your source web page. This ensures you are training on the correct web pages.
                    </Text>
                  </div>
                </Flex>
              </HoverCard.Dropdown>
            </HoverCard>

            <Button
              onClick={() => !disableRetrain && setShowRetrainModal(true)}
              outlined
              disabled={disableRetrain}
              className={classNames(styles.retrainBtn, { [styles.disableBtn]: disableRetrain })}
            >
              Retrain
            </Button>
            <Button onClick={() => setShowRecrawlModal(true)}>
              {hasChildSources ? 'Recrawl' : 'Crawl'}
            </Button>
          </div>
        </div>
        <div className={styles.subText}>
          {hasChildSources ? (
            <>
              By default, Chai absorbs information from all web pages on the same domain.
              <HoverCard
                classNames={{ dropdown: styles.popoverDropdown }}
                width={349}
                position='bottom'
                withArrow
                shadow='md'
              >
                <HoverCard.Target>
                  <ActionIcon variant='transparent'>
                    <InfoGrayIcon />
                  </ActionIcon>
                </HoverCard.Target>
                <HoverCard.Dropdown>
                  <Flex direction='column' gap={10}>
                    <Text size='12px' className='font-poppins' lh='22px'>
                      By default, Chai absorbs information from all web pages on the same domain.
                    </Text>
                    <Text size='12px' className='font-poppins' lh='22px'>
                      If you want it to include information from only certain web pages, you can do
                      that here. These are your active web pages.&nbsp;
                      <Anchor
                        underline='always'
                        c='#08B0F9'
                        target='_blank'
                        href='https://docs.chataid.com/albus-for-slack/company-wiki/web'
                      >
                        Learn more.
                      </Anchor>
                    </Text>
                  </Flex>
                </HoverCard.Dropdown>
              </HoverCard>
            </>
          ) : (
            'Chai is training on the source URL only. If you want it to train on all underlying web pages as well, crawl the source.'
          )}
        </div>

        <TextInput
          mb={24}
          className={styles.input}
          placeholder='Search for web pages and URLs'
          leftSection={<SearchIcon />}
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
            setCount({ active: null });
          }}
        />
      </div>
    </>
  );
};

Header.defaultProps = {
  disableRetrain: false
};

Header.propTypes = {
  searchValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  setCount: PropTypes.func.isRequired,
  setSelectedSources: PropTypes.func.isRequired,
  setIsAllSelected: PropTypes.func.isRequired,
  disableRetrain: PropTypes.bool
};

export default Header;
