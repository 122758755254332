import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Flex, Loader, Text, Tooltip } from '@mantine/core';
import SourceGrid from 'pages/Integrations/Connector/Components/SourceGrid';
import EmptyState from 'pages/Integrations/Connector/Components/EmptyState';
import Table from 'components/Table';
import MultiSelectActions from '../../../../Components/MultiSelectActions';
import { maybePluralize } from 'utils/utilities';
import { stringDecoder } from 'utils/helpers';
import { formatDate } from 'utils/formatters';
import { SOURCES_STATUS } from '../../../constants';
import { ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS, VIEW_TYPE } from 'utils/constants';
import { retrainFailureTooltip, truncateName } from 'pages/Integrations/utils';
import { ReactComponent as ErrorIcon } from 'assets/icons/error_warning.svg';
import { ReactComponent as ActivePageIcon } from 'assets/connectors/active-page-icon.svg';
import InternetIcon from 'assets/icons/internet.svg';
import styles from './styles.module.scss';
import { INTEGRATIONS_TABLE_HEADERS } from 'utils/constant_objects';

const SourcesDataView = (props) => {
  const {
    viewType,
    sources,
    user,
    isLoading,
    pagination,
    pageCount,
    debouncedValue,
    handleManageSourcesClick,
    metaPayload,
    totalItems,
    setTotalItems,
    selectedSources,
    handleCheckboxCta,
    handleDeleteSources,
    isAllSourcesSelected,
    showRemoveSourceModal,
    setShowRemoveSourceModal,
    isDisconnectSourcesLoading
  } = props;

  const isProcessing = sources.some((source) => source.status === 'processing');
  const selectedSourceItems = totalItems.filter((item) => selectedSources.includes(item?.id));
  const tooltipLabels = [
    'Unchecking individual sources when you ‘select all’ is not supported currently. Coming soon.',
    'You cannot select the sources which are under the process. Please wait until they turn active.'
  ];
  const formattedRows = sources?.map((list) => {
    let name = (
      <Flex gap={4} direction='column' align='center' justify='center' className='font-poppins'>
        <Flex gap={4} align='center' justify='center'>
          {list?.error && (
            <Tooltip
              w='400'
              label='Chai could not train on some web pages included in this source. Manage this source to view errors.'
            >
              <Text>
                <ErrorIcon />
              </Text>
            </Tooltip>
          )}

          <Tooltip
            label={
              <Text size='xs' className='wordBreak'>
                {list.originalName || list.name}
              </Text>
            }
            position='top'
            disabled={list.name.length < 25}
            maw='30%'
          >
            <span className={classNames('truncated-text', styles.textHyperlink)}>
              {truncateName(list.name ? list.name : list.url, 25)}
            </span>
          </Tooltip>
          {list?.isParent && list?.childCounts?.active >= 1 && list?.childCounts?.total > 1 && (
            <Tooltip
              w='fit-content'
              label={`${list.childCounts.active}/${list.childCounts.total} ${maybePluralize(
                list.childCounts.active,
                'page'
              )} are included in this source`}
              position='top-center'
              arrowOffset={10}
            >
              <span>
                <ActivePageIcon />
              </span>
            </Tooltip>
          )}
        </Flex>
      </Flex>
    );

    return {
      ...list,
      id: list.id,
      title: (
        <Flex align='center'>
          <Flex className={styles.docTitleWrapper}>
            {list.error && (
              <Tooltip
                w='400'
                label='Chai could not train on some web pages included in this source. Manage this source to view errors.'
              >
                <Text>
                  <ErrorIcon />
                </Text>
              </Tooltip>
            )}
            <Tooltip
              position='top'
              maw={404}
              label={list.name ? stringDecoder(list.name) : list.url}
              disabled={list.name.length < 30}
              className={styles.tooltip}
            >
              <Text className={styles.textHyperlink}>
                {truncateName(list.name ? stringDecoder(list.name) : list.url, 30)}
              </Text>
            </Tooltip>
            {list?.isParent && list?.childCounts?.active >= 1 && list?.childCounts?.total > 1 && (
              <Tooltip
                w='fit-content'
                label={`${list.childCounts.active}/${list.childCounts.total} ${maybePluralize(
                  list.childCounts.active,
                  'page'
                )} are included in this source`}
                position='top-start'
                arrowOffset={10}
              >
                <span>
                  <ActivePageIcon />
                </span>
              </Tooltip>
            )}
          </Flex>
        </Flex>
      ),
      name,
      originalName: list.name,
      sourceIcon: list.url
        ? `https://www.google.com/s2/favicons?domain=${encodeURIComponent(list.url)}&sz=128`
        : InternetIcon,
      date: formatDate(list.lastTrainingStartedAt),
      uploadBy: <div className={styles.uploaderName}>{list?.uploadedBy || 'User'}</div>,
      status:
        (list.status === SOURCES_STATUS.PARTIALLY_ACTIVE ||
          list.status === SOURCES_STATUS.PROCESSING) &&
        list.childCounts.total === list.childCounts.active
          ? 'active'
          : list.status,
      admin: list.admin,
      trainingFrequency: list.trainingFrequency,
      lastTrainingStartedAt: formatDate(list.lastTrainingStartedAt) || list.createdAt,
      lastTrainingAttemptedAt: list.lastTrainingAttemptedAt || list.createdAt,
      error: list.error,
      webpages: list.webpages,
      description: list.description,
      handleCardClick: () => handleManageSourcesClick(list),
      handleManageSourcesClick: () => handleManageSourcesClick(list),
      handleOpenSource: () => list.url && window.open(list.url, '_blank'),
      hasChild: list?.childCounts?.total > 1,
      retrainError: retrainFailureTooltip(
        list.status,
        list.lastTrainType,
        list.lastTrainingAttemptedAt || list.lastTrainingStartedAt
      ),
      disableManageSource: !(list.status === 'error') && list.childCounts.total < 1, //Disable if status is not error and children count is 0
      tooltip: list.name.length > 30 && list.name
    };
  });

  return (
    <>
      {sources.length ? (
        viewType === VIEW_TYPE.GRID ? (
          <SourceGrid
            items={formattedRows}
            totalItems={totalItems}
            setTotalItems={setTotalItems}
            user={user}
            hideCreatedAt={true}
            sourceIcon={InternetIcon}
            pagination={pagination}
            pageCount={pageCount}
            isLoading={isLoading}
            showHoverEffect
            hideManageAutoRetrain
            hideRetrainNow
            showManageSourceForWeb
            showEditName
            metaPayload={metaPayload}
            selectedSources={selectedSources}
            isAllSourcesSelected={isAllSourcesSelected}
            handleCheckboxCta={handleCheckboxCta}
            showCheckbox
            checkboxTooltipLabels={tooltipLabels}
          />
        ) : (
          <Table
            headers={INTEGRATIONS_TABLE_HEADERS}
            rows={formattedRows}
            hideCreatedAt={true}
            pagination={pagination}
            pageCountFromProp={pageCount}
            pageLimitFromProp={ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS}
            isLoading={isLoading}
            sourceIcon={InternetIcon}
            rowClickHandler={(row) => handleManageSourcesClick(row)}
            hideManageAutoRetrain
            hideRetrainNow
            showManageSourceForWeb
            showEditName
            metaPayload={metaPayload}
            totalCount={formattedRows.length}
            selectedSources={selectedSources}
            isAllSourcesSelected={isAllSourcesSelected}
            handleCheckboxCta={handleCheckboxCta}
            showCheckbox
            checkboxTooltipLabels={tooltipLabels}
          />
        )
      ) : isLoading ? (
        <Flex align='center' justify='center' h='55vh'>
          <Loader />
        </Flex>
      ) : debouncedValue ? (
        <EmptyState hideButton text='No sources found.' />
      ) : null}
      {(isAllSourcesSelected || selectedSources.length > 0) && (
        <MultiSelectActions
          selectedSources={selectedSourceItems}
          selectAll={isAllSourcesSelected}
          isProcessing={isProcessing}
          handleDeleteSources={handleDeleteSources}
          showRemoveSourceModal={showRemoveSourceModal}
          setShowRemoveSourceModal={setShowRemoveSourceModal}
          isDisconnectSourcesLoading={isDisconnectSourcesLoading}
        />
      )}
    </>
  );
};

SourcesDataView.propTypes = {
  viewType: PropTypes.string,
  setViewType: PropTypes.func,
  sources: PropTypes.array,
  isDisconnectSourcesLoading: PropTypes.bool,
  selectedSources: PropTypes.array,
  totalItems: PropTypes.array,
  setTotalItems: PropTypes.func,
  exlcludedSources: PropTypes.array,
  isAllSourcesSelected: PropTypes.bool,
  handleCheckboxCta: PropTypes.func,
  handleDeleteSources: PropTypes.func,
  showRemoveSourceModal: PropTypes.bool,
  setShowRemoveSourceModal: PropTypes.func,
  user: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  showFilter: PropTypes.bool,
  filter: PropTypes.string,
  handleFilterchange: PropTypes.func,
  pagination: PropTypes.shape({
    active: PropTypes.number,
    setPage: PropTypes.func
  }).isRequired,
  pageCount: PropTypes.number.isRequired,
  searchValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  debouncedValue: PropTypes.string.isRequired,
  teamName: PropTypes.string.isRequired,
  handleManageSourcesClick: PropTypes.func.isRequired,
  metaPayload: PropTypes.shape({
    provider: PropTypes.string,
    params: {
      teamId: PropTypes.string,
      page: PropTypes.number,
      limit: PropTypes.number,
      q: PropTypes.string
    }
  }).isRequired
};

export default SourcesDataView;
