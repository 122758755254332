import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { isValidPhoneNumber } from 'react-phone-number-input';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ActionIcon, Button, TextInput, Textarea } from '@mantine/core';
import { getCountryForTimezone } from 'countries-and-timezones';
import Chip from 'components/Chip';
import InformationContainer from '../LeftPanel/View';
import PhoneNumber from 'components/FormElements/PhoneNumber';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import { STEPS, YOUR_ROLE, YOUR_TEAM_SIZE } from 'pages/OnBoarding/constants';
import styles from './styles.module.scss';

const About = (props) => {
  const { step, handleStepChange, isLoading } = props;

  const [yourRole, setyourRole] = useState('');
  const [yourTeamSize, setyourTeamSize] = useState('');
  const [yourUseCase, setYourUseCase] = useState('');
  const [yourOtherRole, setyourOtherRole] = useState('');
  const [selectedCountry, setselectedCountry] = useState('US');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isFormSubmittedOnce, setIsFormSubmittedOnce] = useState(false);
  const roles = yourOtherRole ? YOUR_ROLE.filter((role) => role !== 'Other') : YOUR_ROLE;

  useEffect(() => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const country = getCountryForTimezone(userTimezone).id.toLowerCase();
    setselectedCountry(country);
  }, []);

  const handleSubmit = () => {
    const payload = {
      role: yourRole,
      size: yourTeamSize,
      useCase: [yourUseCase.trim()]
    };

    if (
      !yourRole.length ||
      !yourTeamSize.length ||
      (yourRole === 'Other' && !yourOtherRole.trim())
    ) {
      toast.error('Please fill all fields');
      return;
    }

    if (selectedCountry?.length && phoneNumber?.length) {
      const isValid = isValidPhoneNumber(phoneNumber, selectedCountry);
      if (!isValid) {
        return;
      }

      payload.phone = {
        countryCode: selectedCountry,
        number: phoneNumber
      };
    }

    handleStepChange(STEPS.CONNECTORS, payload);
  };

  const handleInputChange = (e) => {
    if (e.key === 'Enter' || e.type === 'blur') {
      setyourOtherRole(e.target.value);
      setyourRole(e.target.value);
    }
  };

  return (
    <div className={styles.wrapper}>
      <InformationContainer step={step} />
      <div className={styles.container}>
        <div className={styles.body}>
          <h1>Tell us a little bit about you</h1>
          <div className={styles.chips}>
            <h2>Your role</h2>
            <div className={styles.chipsGroup}>
              {roles.map((role) => (
                <Chip
                  label={role}
                  value={role}
                  selectedItem={yourRole}
                  handleClick={(role) => setyourRole(role)}
                  key={role}
                />
              ))}
              {yourRole === 'Other' && (
                <TextInput
                  radius='lg'
                  type='text'
                  onKeyDownCapture={handleInputChange}
                  onBlur={handleInputChange}
                  placeholder='Enter your role'
                  rightSection={
                    <ActionIcon variant='transparent' onClick={() => setyourRole('')}>
                      <SVGIcon name={ICONS_LIST.CROSS_RED} height={14} width={14} />
                    </ActionIcon>
                  }
                />
              )}
              {yourRole !== 'Other' && yourOtherRole && (
                <Chip
                  label={yourOtherRole}
                  value={yourOtherRole}
                  selectedItem={yourRole}
                  showCloseIcon
                  handleClick={(role) => setyourRole(role)}
                  handleCrossClick={() => setyourOtherRole('')}
                />
              )}
            </div>
          </div>
          <div className={styles.chips}>
            <h2>Your team size</h2>
            <div className={styles.chipsGroup}>
              {YOUR_TEAM_SIZE.map((size) => (
                <Chip
                  label={size}
                  value={size}
                  key={size}
                  selectedItem={yourTeamSize}
                  handleClick={(size) => {
                    setyourTeamSize(size);
                  }}
                />
              ))}
            </div>
          </div>

          <div
            className={classNames(styles.phoneNumber, {
              [styles.errorInput]:
                isFormSubmittedOnce &&
                phoneNumber &&
                !isValidPhoneNumber(phoneNumber, selectedCountry)
            })}
          >
            <h2>
              Your phone number <span>(optional)</span>
            </h2>
            <PhoneNumber
              setPhoneNumber={setPhoneNumber}
              selectedCountry={selectedCountry}
              setSelectedCountry={setselectedCountry}
            />
            {isFormSubmittedOnce &&
              phoneNumber &&
              phoneNumber.length > 0 &&
              !isValidPhoneNumber(phoneNumber, selectedCountry) && (
                <div className={styles.error}>The phone number is not in the correct format</div>
              )}
          </div>
          <Textarea
            w='100%'
            classNames={{
              label: styles.useCaseLabel,
              input: styles.useCaseInput
            }}
            label={
              <>
                How do you plan to use Chat Aid ? <span>(optional)</span>
              </>
            }
            placeholder={
              "Do you want to search for answers across apps like Slack, Jira and Google Drive? Or are you looking for an employee helpdesk?\n\nTell us what you want to do and our account manager will help you set things up. Don't be shy, give us all the details! 😇"
            }
            onChange={(e) => setYourUseCase(e.target.value)}
          />
        </div>
        <Button
          loading={isLoading}
          className={classNames('primaryBtn', styles.button)}
          onClick={() => {
            setIsFormSubmittedOnce(true);
            handleSubmit();
          }}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

About.propTypes = {
  step: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleStepChange: PropTypes.func.isRequired
};

export default About;
