import PropTypes from 'prop-types';
import { Select, Text } from '@mantine/core';
import classNames from 'classnames';
import {
  CATEGORY_TAB,
  CONNECTOR_CATEGORIES,
  CONNECTOR_CATEGORY_LIST_FOR_DROPDOWN
} from 'pages/ControlPanel/constants';
import styles from './styles.module.scss';

const ConnectionsCategoryList = (props) => {
  const {
    activeCategory,
    connectedCount,
    categories,
    handleCategoryChange,
    hideMyConnections,
    connectorCatrgoryForMobileSelect,
    searchTerm
  } = props;

  const categoryList = categories.map((cat) => (
    <div
      key={cat.name}
      className={classNames(styles.item, {
        [styles.active]: activeCategory === cat.name,
        [styles.inactiveItem]: activeCategory !== cat.name
      })}
      onClick={() => {
        setTimeout(() => {
          //since this onclick getting called before the element render in dom, adding setTimeout to escape the event loop, no latency since, timeoute is 0.
          document.getElementById(cat.name).scrollIntoView({ behavior: 'smooth' });
        }, 0);
        handleCategoryChange(cat.name);
      }}
    >
      {cat.name}
    </div>
  ));
  return (
    <div className={classNames(styles.wrapper, { [styles.hideConnectionsCategory]: searchTerm })}>
      {!hideMyConnections && (
        <Text
          className={classNames(styles.myConnection, {
            [styles.active]: activeCategory === CATEGORY_TAB.MY_CONNECTIONS,
            [styles.myConnectionActive]: activeCategory === CATEGORY_TAB.MY_CONNECTIONS
          })}
          onClick={() => handleCategoryChange(CATEGORY_TAB.MY_CONNECTIONS)}
        >
          My connections ({connectedCount})
        </Text>
      )}
      <div className={styles.categoriesWrapper}>
        <p
          onClick={() =>
            handleCategoryChange(
              activeCategory === CATEGORY_TAB.MY_CONNECTIONS
                ? CONNECTOR_CATEGORIES[0].name
                : activeCategory
            )
          }
          className={styles.title}
        >
          CATEGORIES
        </p>
        <div className={styles.categories}>{categoryList}</div>
      </div>
      {/* for mobile view */}
      <Select
        radius={4}
        allowDeselect={false}
        className={classNames(styles.categoryDropdown, 'select-style-v1')}
        data={connectorCatrgoryForMobileSelect}
        value={activeCategory}
        onChange={(value) => {
          handleCategoryChange(value);
        }}
      />
    </div>
  );
};

ConnectionsCategoryList.defaultProps = {
  hideMyConnections: false,
  connectorCatrgoryForMobileSelect: CONNECTOR_CATEGORY_LIST_FOR_DROPDOWN,
  searchTerm: ''
};

ConnectionsCategoryList.propTypes = {
  connectedCount: PropTypes.number.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired
    })
  ).isRequired,
  activeCategory: PropTypes.string.isRequired,
  handleCategoryChange: PropTypes.func.isRequired,
  hideMyConnections: PropTypes.bool,
  connectorCatrgoryForMobileSelect: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      count: PropTypes.number
    })
  ),
  searchTerm: PropTypes.string
};

export default ConnectionsCategoryList;
