import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Flex, Modal, Text } from '@mantine/core';
import { AddSourceButtonUI } from '../../Components/AddSourceButton';
import { NAVBAR_ROUTES } from 'utils/constants';

const AddSourceButtonWithModal = ({ provider, isDrivesCta }) => {
  const [opened, setOpened] = useState(false);
  const navigate = useNavigate();
  const { connections } = useSelector((state) => state.connections);
  const filteredConnector = connections.find((conn) => conn.name === provider);

  const title = filteredConnector?.autoConnect
    ? 'Enable this connection to add a source'
    : 'Connect your account';

  const body = filteredConnector?.autoConnect ? (
    <div className='font-poppins'>
      To add a source, enable&nbsp;
      <Text tt='capitalize' display='inline' fw='bold' size='sm'>
        {filteredConnector?.title}
      </Text>
      &nbsp;from&nbsp;
      <strong>Settings &gt; Connections</strong>
    </div>
  ) : (
    <div className='font-poppins'>
      To add a source, connect your&nbsp;
      <Text tt='capitalize' display='inline' fw='bold' size='sm'>
        {filteredConnector?.title}
      </Text>
      &nbsp; account under &nbsp;
      <strong>Settings &gt; Connections</strong>
    </div>
  );

  return (
    <>
      {opened && (
        <Modal
          opened={true}
          onClose={() => setOpened(false)}
          className='mobile-modal'
          centered
          title={
            <Text size='lg' fw='bold' mb={7}>
              {title}
            </Text>
          }
          p={24}
          size={572}
        >
          {body}
          <Flex justify={'end'} gap={16} mt={45}>
            <Button className='primaryBtnOutline' onClick={() => setOpened(false)}>
              Cancel
            </Button>
            <Button
              onClick={() =>
                navigate(
                  `${NAVBAR_ROUTES.SETTINGS_TABS.CONNECTIONS}?connector=${filteredConnector.name}`
                )
              }
              className='primaryBtn'
            >
              Open settings
            </Button>
          </Flex>
        </Modal>
      )}

      <AddSourceButtonUI isDrivesCta={isDrivesCta} handleAddSource={() => setOpened(true)} />
    </>
  );
};

AddSourceButtonWithModal.defaultProps = {
  isDrivesCta: false
};

AddSourceButtonWithModal.propTypes = {
  provider: PropTypes.string.isRequired,
  isDrivesCta: PropTypes.bool
};

export default AddSourceButtonWithModal;
