import { Divider, Flex, Popover, Text, Title, UnstyledButton, Tooltip } from '@mantine/core';
import PropTypes from 'prop-types';
import { POPUP, usePopupContext } from 'contexts/PopupContext';
import ProfilePicture from 'components/ProfilePicture';
import { truncateName } from 'pages/Integrations/utils';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';

const Logout = (props) => {
  const { user, collapsed, screen991, styles, logoutUser } = props;
  const { openedPopup, setOpenedPopup } = usePopupContext();

  return (
    <Popover
      width={200}
      arrowSize={12}
      arrowPosition='center'
      position={screen991 ? 'top' : 'right-end'}
      withArrow
      data-testid='navbar-logout'
      shadow='md'
      opened={openedPopup === POPUP.LOGOUT}
      onChange={() => setOpenedPopup(POPUP.LOGOUT)}
    >
      <Popover.Target>
        <Flex
          align='center'
          justify='start'
          className='cursor-pointer'
          onClick={() => setOpenedPopup(POPUP.LOGOUT)}
        >
          <ProfilePicture photoUrl={user?.photo} name={user?.name} />
          {!collapsed && (
            <Tooltip label={user?.name} disabled={user?.name.length < 15}>
              <Text className={styles.profileName}>{truncateName(user?.name, 15)}</Text>
            </Tooltip>
          )}
        </Flex>
      </Popover.Target>
      <Popover.Dropdown className={styles.logoutPopover}>
        <Flex align='center'>
          <ProfilePicture photoUrl={user?.photo} name={user?.name} />
          <div className={styles.logoutContent}>
            <Tooltip label={user?.name} disabled={user?.name.length < 20}>
              <Title order={6}>{truncateName(user?.name, 20)}</Title>
            </Tooltip>
            <Tooltip disabled={user?.email?.length < 25} label={user?.email}>
              <Text className={styles.emailTooltip}>{truncateName(user?.email, 25)}</Text>
            </Tooltip>
          </div>
        </Flex>
        <Divider my='sm' color={'#f2f2f2'} />

        <UnstyledButton
          className={styles.logoutBtn}
          data-testid='navbar-logout-button'
          onClick={logoutUser}
        >
          <LogoutIcon /> <Text className={styles.logoutBtnText}>Logout</Text>
        </UnstyledButton>
      </Popover.Dropdown>
    </Popover>
  );
};

Logout.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    photo: PropTypes.string.isRequired
  }),
  collapsed: PropTypes.bool.isRequired,
  screen991: PropTypes.bool.isRequired,
  styles: PropTypes.shape({
    profileName: PropTypes.string.isRequired,
    logoutPopover: PropTypes.string.isRequired,
    logoutContent: PropTypes.string.isRequired,
    logoutBtn: PropTypes.string.isRequired,
    logoutBtnText: PropTypes.string.isRequired,
    emailTooltip: PropTypes.string.isRequired
  }).isRequired,
  logoutUser: PropTypes.func.isRequired
};

export default Logout;
