import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Badge, CloseButton, Flex, Image, Modal, Text, Title } from '@mantine/core';
import Button from 'components/Button';
import { useUpdateUserMutation } from 'redux/services/user';
import { FEATURE_FLAGS, NAVBAR_ROUTES } from 'utils/constants';
import IntroductionModalIllustration from 'assets/actions/introduction-modal-illustration.png';
import styles from './styles.module.scss';

const ActionsIntroductionModal = ({ setOpen }) => {
  const navigate = useNavigate();
  const [updateUser] = useUpdateUserMutation();

  const handleClose = async (isLearnMoreClicked) => {
    if (isLearnMoreClicked) {
      navigate(NAVBAR_ROUTES.ACTIONS);
    }
    setOpen(false);

    await updateUser({
      featuresExploredFlags: [
        {
          featureName: FEATURE_FLAGS.ACTIONS_INTRODUCTION_MODAL,
          state: 'inactive'
        }
      ]
    });
  };

  return (
    <Modal
      centered
      opened={true}
      size={700}
      radius={8}
      onClose={() => handleClose(false)}
      withCloseButton={false}
      classNames={{ body: styles.modalBody }}
    >
      <Flex direction='column' align='center' gap={24} className={styles.container}>
        <CloseButton onClick={() => handleClose(false)} className={styles.closeBtn} />
        <Badge
          size='xs'
          variant='gradient'
          gradient={{
            from: '#008FD0 4.3%',
            to: '#6129CF 121.81%',
            deg: 85
          }}
          className={styles.newBadge}
          classNames={{ label: styles.badgeLabel }}
        >
          New
        </Badge>
        <Flex justify='center' className={styles.imageContainer}>
          <Image w={'100%'} src={IntroductionModalIllustration} />
        </Flex>
        <Flex direction='column' align='center' className={styles.textContainer}>
          <Title className={styles.modalTitle}>Simplify your workflow with actions</Title>
          <Text size={14} fw={400} c='#5E5873' lh={1.4} mb={19}>
            Actions designed to streamline such as creating Jira tickets, scheduling events, and
            updating CRM (contacts, opportunities etc.) directly from Slack.
          </Text>
          <Button onClick={() => handleClose(true)} className={styles.learnMoreBtn}>
            Learn more
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

ActionsIntroductionModal.propTypes = {
  setOpen: PropTypes.func.isRequired
};

export default ActionsIntroductionModal;
