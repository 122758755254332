import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Box, Text, Title, Tooltip, Flex, Checkbox } from '@mantine/core';
import ActionMenu from '../../ActionMenu';
import ProfilePicture from 'components/ProfilePicture';
import SourceIcon from 'pages/Integrations/Connector/Web/DataView/Components/SourceIcon';
import { stringDecoder } from 'utils/helpers';
import { isRetrainDisabled } from 'pages/Integrations/utils';
import { formatDate } from 'utils/formatters';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import { ACCOUNT_BASED_CONNECTORS } from 'pages/ControlPanel/constants';
import { SOURCES_STATUS } from '../../../Web/constants';
import ErrorIcon from 'assets/icons/error_icon.svg';
import styles from './styles.module.scss';

const SourceGridItem = (props) => {
  const {
    item,
    sourceIcon,
    hideCreatedAt,
    hideLastTrained,
    hideUploadedBy,
    showManageResources,
    hideDisconnect,
    domainTitle,
    showManageSitemapSources,
    handleSitemapManageSource,
    showHoverEffect,
    hideRetrainNow,
    hideManageAutoRetrain,
    messageSourceDeleteModal,
    showManageSourceForWeb,
    showEditName,
    metaPayload,
    setTotalItems,
    selectedSources,
    handleCheckboxCta,
    showCheckbox,
    isAllSourcesSelected,
    checkboxTooltipLabels,
    hideActionMenu
  } = props;

  const { provider } = useParams();
  const { isDisabled, message: customMessage } = isRetrainDisabled(
    item.lastTrainingStartedAt,
    provider
  );
  let status = item.status;
  let message = customMessage;
  const isSourceArchived = item.status === 'archived';
  if (isSourceArchived) {
    status = 'active';
    message =
      metaPayload.provider === 'google'
        ? 'Chai does not have access to this Google Drive file anymore. To retrain on it, remove this file and add it back.'
        : 'Cannot train on archived Slack channels.';
  }

  const disableDisconnect = !item.isSlackMessage && item.status === 'processing';
  const isCustomSource = provider === 'custom';
  const showRetryAdding = isCustomSource && item.status === 'error';

  const lastTrainingStartedAt = new Date(item.lastTrainingStartedAt);
  const lastTrainingAttemptedAt = new Date(item.lastTrainingAttemptedAt);
  const maxDate = new Date(
    Math.max(lastTrainingStartedAt.getTime(), lastTrainingAttemptedAt.getTime())
  );
  const formattedMaxDate = formatDate(maxDate, 'MMM DD, YYYY hh:mm:ss A');
  const isProcessing = item.status === SOURCES_STATUS.PROCESSING;

  function handleSourceNameClick(event) {
    event.stopPropagation();
    item?.handleOpenSource();
  }

  return (
    <div
      onClick={item?.handleCardClick}
      className={classNames(styles.documentContainer, {
        [styles.showHoverEffect]: showHoverEffect && !item.disableManageSource
      })}
    >
      <div className={styles.body}>
        <div className={styles.checkbox}>
          {showCheckbox && (
            <Tooltip
              disabled={!isProcessing && !isAllSourcesSelected}
              w={390}
              label={
                isAllSourcesSelected && !isProcessing
                  ? checkboxTooltipLabels[0]
                  : checkboxTooltipLabels[1]
              }
            >
              <Checkbox
                onClick={(e) => e.stopPropagation()}
                onChange={() => handleCheckboxCta(item)}
                color='primaryGreen.3'
                radius={4}
                checked={
                  !isProcessing && (isAllSourcesSelected || selectedSources?.includes(item.id))
                }
                disabled={isProcessing}
                classNames={{
                  input: classNames({
                    ['cursor-notAllowed']: isAllSourcesSelected,
                    ['cursor-pointer']: !isAllSourcesSelected
                  })
                }}
              />
            </Tooltip>
          )}

          <div className={styles.docStatus}>
            {status && (
              <Box
                className={classNames(
                  styles.statusContainer,
                  status === 'active' ? styles.inCompleted : styles.inProcess
                )}
              >
                {item.error && !isSourceArchived ? (
                  <Tooltip w={300} label={item.error} classNames={{ tooltip: styles.tooltip }}>
                    <span className='capitalize'>{status}</span>
                  </Tooltip>
                ) : (
                  <span className={classNames('capitalize', styles.statusText)}>{status}</span>
                )}
              </Box>
            )}
            <ActionMenu
              hideActionMenu={hideActionMenu}
              showRetryAdding={showRetryAdding}
              item={item}
              disableRetrain={isSourceArchived || isDisabled}
              showManageResources={showManageResources}
              hideDisconnect={hideDisconnect}
              disableDisconnect={disableDisconnect}
              disableAutoRetrain={isSourceArchived}
              disableDisableAutoRetrain={isSourceArchived}
              showManageSitemapSources={showManageSitemapSources}
              handleSitemapManageSource={() => handleSitemapManageSource(item)}
              disableAutoRetrainMessage={message}
              hideRetrainNow={hideRetrainNow}
              hideManageAutoRetrain={hideManageAutoRetrain}
              messageSourceDeleteModal={messageSourceDeleteModal}
              showManageSourceForWeb={showManageSourceForWeb}
              sourceIcon={sourceIcon}
              showEditName={showEditName}
              metaPayload={metaPayload}
              setTotalItems={setTotalItems}
            />
          </div>
        </div>
        {sourceIcon ? (
          <SourceIcon sourceIconStyles={styles.docIconContainer} url={sourceIcon} />
        ) : null}

        <Title order={4} className={styles.fileName} onClick={(e) => handleSourceNameClick(e)}>
          {typeof item.name === 'string' ? (
            <Tooltip
              maw={404}
              p={12}
              disabled={item.name.length < 75}
              label={<Text size='xs'>{item.originalName || item.name}</Text>}
              position='top'
            >
              <span
                className={classNames({
                  'truncated-text': true,
                  [styles.noUrlSourceTitle]: item.url === undefined,
                  [styles.sourceTitle]: item.url,
                  underline: !ACCOUNT_BASED_CONNECTORS.includes(metaPayload.provider)
                })}
              >
                {stringDecoder(item.name)}
              </span>
            </Tooltip>
          ) : (
            <span
              className={classNames({
                [styles.noUrlSourceTitle]: item.url === undefined,
                [styles.sourceTitle]: item.url,
                underline: !ACCOUNT_BASED_CONNECTORS.includes(metaPayload.provider)
              })}
            >
              {stringDecoder(item.name)}
            </span>
          )}
        </Title>
      </div>

      <div className={styles.docFooter}>
        <div className={styles.docFooterContent}>
          {hideCreatedAt ? null : (
            <>
              <Title order={6}>Uploaded on:</Title>
              <Text className={styles.text}>{item.createdAt}</Text>
            </>
          )}
          {hideLastTrained || !item.lastTrainingStartedAt ? null : (
            <>
              <div className={styles.label}>
                {isCustomSource ? 'Added on:' : 'Last trained:'}
                <Tooltip
                  w={300}
                  label={
                    isCustomSource
                      ? 'Since Chat Aid cannot access your local custom files, you cannot retrain him on updated content. Instead, remove the outdated file from your wiki and add the updated file as a new source to ensure Chat Aid is up to date.'
                      : 'When you update the contents of a source, retrain Chat Aid to absorb the updated content and ensure it is up to date.'
                  }
                >
                  <div className={styles.infoIcon}>
                    <SVGIcon name={ICONS_LIST.TOOL_TIP} className={styles.img} />
                  </div>
                </Tooltip>
              </div>
              <Flex justify='start' align='left' gap={6}>
                <Text className={styles.text}>{formattedMaxDate}</Text>
                {item.retrainError && (
                  <Tooltip label={item.retrainError}>
                    <img height={18} width={18} src={ErrorIcon} />
                  </Tooltip>
                )}
              </Flex>
            </>
          )}
        </div>
        {item?.domainName && (
          <div
            className={styles.docFooterContent}
            style={hideUploadedBy ? { textAlign: 'right' } : { textAlign: 'left' }}
          >
            <Title order={6}>{domainTitle || 'Workspace:'}</Title>
            <Text className={styles.text}>{item.domainName}</Text>
          </div>
        )}
        {!hideUploadedBy && (
          <Tooltip
            styles={{ tooltip: { textTransform: 'capitalize' } }}
            label={item?.admin?.name}
            multiline={false}
          >
            <span>
              <ProfilePicture photoUrl={item?.admin?.photo} name={item?.admin?.name} />
            </span>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

SourceGridItem.defaultProps = {
  showManageSitemapSources: false,
  handleSitemapManageSource: () => null,
  hideRetrainNow: false,
  hideManageAutoRetrain: false,
  handleCheckboxCta: () => null,
  selectedSources: [],
  exlcludedSources: [],
  checkboxTooltipLabels: [],
  showCheckbox: false,
  item: {
    tooltip: '',
    originalName: '',
    sitemap: false,
    isSlackMessage: false,
    url: '',
    disableManageSource: false
  },
  user: {},
  isAllSourcesSelected: false,
  hideActionMenu: false
};

SourceGridItem.propTypes = {
  item: PropTypes.object,
  sourceIcon: PropTypes.string,
  checkboxTooltipLabels: PropTypes.array,
  user: PropTypes.object,
  hideCreatedAt: PropTypes.bool,
  hideLastTrained: PropTypes.bool,
  hideUploadedBy: PropTypes.bool,
  hideDisconnect: PropTypes.bool,
  showManageResources: PropTypes.bool,
  domainTitle: PropTypes.string,
  showManageSitemapSources: PropTypes.bool,
  handleSitemapManageSource: PropTypes.func,
  showHoverEffect: PropTypes.bool,
  hideRetrainNow: PropTypes.bool,
  selectedSources: PropTypes.array,
  exlcludedSources: PropTypes.array,
  handleCheckboxCta: PropTypes.func,
  hideManageAutoRetrain: PropTypes.bool,
  messageSourceDeleteModal: PropTypes.bool,
  showManageSourceForWeb: PropTypes.bool,
  hideActionMenu: PropTypes.bool,
  showEditName: PropTypes.bool,
  metaPayload: PropTypes.shape({
    provider: PropTypes.string,
    params: PropTypes.shape({
      teamId: PropTypes.string,
      page: PropTypes.number,
      limit: PropTypes.number,
      q: PropTypes.string
    })
  }).isRequired,
  setTotalItems: PropTypes.func.isRequired,
  showCheckbox: PropTypes.bool,
  isAllSourcesSelected: PropTypes.bool
};

export default SourceGridItem;
