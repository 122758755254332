import PropTypes from 'prop-types';
import DeleteWarningModal from 'components/Modals/DeleteWarningModal';
import { SOURCE_ACTION } from '../../../constants';

const ConfirmationModal = (props) => {
  const { action, isMulty, setShowConfirmationModalData, handleConfirm, loading } = props;

  let header = `Are you sure want to retrain Chai on ${isMulty ? 'these' : 'this'} web page${
    isMulty ? 's' : ''
  }?`;

  let description =
    "When Chai retrains on a web page, it looks for updated content and absorbs it. This ensures he's up to date.";

  if (action === SOURCE_ACTION.EXCLUDE) {
    header = `Are you sure you want to exclude ${isMulty ? 'these' : 'this'} web page${
      isMulty ? 's' : ''
    }?`;
    description =
      'When you exclude this web page from training, Chai will no longer remember its content and answer questions using it.\nHe also stops retraining on this page.';
  }

  if (action === SOURCE_ACTION.EXCLUDE_WILL_REMOVE_SOURCE) {
    header = `Are you sure you want remove this source?`;
    description = `You selected to exclude all included web pages in this source. When you continue, you will remove the entire source altogether.`;
  }

  if (action === SOURCE_ACTION.INCLUDE) {
    header = `Are you sure you want to include ${isMulty ? 'these' : 'this'} web page${
      isMulty ? 's' : ''
    }?`;
    description =
      'When you include a web page for training, Chai remembers its content and answers questions using it. It also starts retraining on this page to stay up to date.';
  }

  function handleClose() {
    setShowConfirmationModalData({ open: false, action: null, data: null });
  }

  return (
    <DeleteWarningModal
      header={header}
      title=''
      body={description}
      onClose={handleClose}
      handleCancel={handleClose}
      handleDelete={handleConfirm}
      open={true}
      buttonColor={
        action === SOURCE_ACTION.EXCLUDE || action === SOURCE_ACTION.EXCLUDE_WILL_REMOVE_SOURCE
          ? '#FF5252'
          : '#1CA555'
      }
      removeText={
        action === SOURCE_ACTION.EXCLUDE_WILL_REMOVE_SOURCE
          ? 'Remove'
          : action === SOURCE_ACTION.EXCLUDE
            ? 'Exclude'
            : 'Confirm'
      }
      loading={loading}
    />
  );
};

ConfirmationModal.defaultProps = {
  isMulty: false,
  loading: false
};

ConfirmationModal.propTypes = {
  action: PropTypes.string.isRequired,
  isMulty: PropTypes.bool,
  setShowConfirmationModalData: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

export default ConfirmationModal;
