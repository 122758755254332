import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Anchor, Menu, Tooltip, UnstyledButton } from '@mantine/core';
import { ReactComponent as ActionIcon } from 'assets/icons/3-v-dots.svg';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import styles from '../styles.module.scss';

const UI = (props) => {
  const {
    provider,
    showRetryAdding,
    handleDisconnect,
    handleRetrain,
    disableRetrain,
    handleAutoRetrain,
    handleManageConnection,
    disableManageSource,
    showManageResources,
    hideDisconnect,
    showManageSourceForWeb,
    showEditName,
    setShowEditNameModal,
    handleManageSourceForWeb,
    disableDisconnect,
    disableAutoRetrain,
    disableAutoRetrainMessage,
    hideRetrainNow,
    hideManageAutoRetrain,
    editDescriptionClickHandler,
    hideActionMenu
  } = props;

  return (
    <Menu position='right' className={classNames({ [styles.hideActionMenu]: hideActionMenu })}>
      <Menu.Target>
        <UnstyledButton
          pl={10}
          pr={20}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <ActionIcon />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown onClick={(e) => e.stopPropagation()}>
        {(!hideRetrainNow || (provider === 'custom' && showRetryAdding)) && (
          <Menu.Item
            mb={4}
            onClick={disableRetrain ? undefined : handleRetrain}
            className={classNames({ [styles.disable]: disableRetrain })}
          >
            {showRetryAdding ? 'Retry adding' : 'Retrain now'}
            {disableRetrain ? (
              <Tooltip label={disableAutoRetrainMessage} multiline={false}>
                <span>
                  <SVGIcon name={ICONS_LIST.TOOL_TIP} height={14} width={14} />
                </span>
              </Tooltip>
            ) : null}
          </Menu.Item>
        )}

        {!hideManageAutoRetrain && (
          <Menu.Item
            mb={4}
            onClick={disableAutoRetrain ? undefined : handleAutoRetrain}
            className={classNames({ [styles.disable]: disableAutoRetrain })}
            disabled={disableAutoRetrain}
          >
            Manage auto-retrain
          </Menu.Item>
        )}

        {showManageSourceForWeb && (
          <Menu.Item
            mb={4}
            onClick={(e) => !disableManageSource && handleManageSourceForWeb(e)}
            className={classNames({ [styles.disable]: disableManageSource })}
          >
            Manage
            {disableManageSource && (
              <Tooltip
                label='You can manage this source after Chat Aid processes it.'
                multiline={false}
              >
                <span>
                  <SVGIcon name={ICONS_LIST.TOOL_TIP} height={14} width={14} />
                </span>
              </Tooltip>
            )}
          </Menu.Item>
        )}

        {showManageResources && (
          <Menu.Item mb={4} onClick={handleManageConnection}>
            Manage connections
            <Tooltip
              classNames={{
                tooltip: styles.manageSourceTooltip
              }}
              closeDelay={1000}
              label={
                <div onClick={(e) => e.stopPropagation()}>
                  To manage your internal sources in Notion,
                  <br /> manage the connection built by Chat Aid
                  <br />
                  through your Notion account.
                  <Anchor
                    href='https://www.notion.so/help/add-and-manage-connections-with-the-api#add-connections-to-pages'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Read more.
                  </Anchor>
                </div>
              }
              multiline={false}
            >
              <span style={{ marginLeft: 16 }}>
                <SVGIcon name={ICONS_LIST.TOOL_TIP} height={14} width={14} />
              </span>
            </Tooltip>
          </Menu.Item>
        )}

        <Menu.Item mb={4} onClick={editDescriptionClickHandler}>
          Edit description
        </Menu.Item>

        {showEditName && (
          <Menu.Item mb={4} onClick={() => setShowEditNameModal(true)}>
            Edit name
          </Menu.Item>
        )}

        {!hideDisconnect && (
          <Menu.Item
            mb={4}
            onClick={(e) => {
              e.stopPropagation();
              !disableDisconnect && handleDisconnect();
            }}
            className={classNames({ [styles.disable]: disableDisconnect })}
          >
            Remove
            {disableDisconnect ? (
              <Tooltip
                label='You can disconnect this source after it is processed.'
                multiline={false}
              >
                <span>
                  <SVGIcon name={ICONS_LIST.TOOL_TIP} height={14} width={14} />
                </span>
              </Tooltip>
            ) : null}
          </Menu.Item>
        )}
      </Menu.Dropdown>
    </Menu>
  );
};

UI.defaultProps = {
  showManageSitemapSources: false,
  hideRetrainNow: false,
  hideManageAutoRetrain: false,
  showRetryAdding: false,
  disableManageSource: false,
  hideActionMenu: false,
  disableAutoRetrainMessage: ''
};

UI.propTypes = {
  provider: PropTypes.string,
  showRetryAdding: PropTypes.bool,
  hideDisconnect: PropTypes.bool,
  showManageResources: PropTypes.bool,
  handleManageConnection: PropTypes.func,
  handleDisconnect: PropTypes.func,
  handleRetrain: PropTypes.func,
  handleAutoRetrain: PropTypes.func,
  showAutoTrain: PropTypes.bool,
  disableRetrain: PropTypes.bool,
  disableDisconnect: PropTypes.bool,
  disableAutoRetrain: PropTypes.bool,
  showManageSitemapSources: PropTypes.bool,
  handleSitemapManageSource: PropTypes.func,
  disableAutoRetrainMessage: PropTypes.string,
  hideRetrainNow: PropTypes.bool,
  hideManageAutoRetrain: PropTypes.bool,
  disableManageSource: PropTypes.bool,
  editDescriptionClickHandler: PropTypes.func,
  showManageSourceForWeb: PropTypes.bool,
  showEditName: PropTypes.bool,
  setShowEditNameModal: PropTypes.func,
  handleManageSourceForWeb: PropTypes.func,
  hideActionMenu: PropTypes.bool
};
export default UI;
