import { useEffect, useState } from 'react';
import { Flex, Text, Grid, Skeleton, Loader } from '@mantine/core';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { toast } from 'react-toastify';
import { useDebouncedValue, useMediaQuery } from '@mantine/hooks';
import { useFetchQuestionsQuery, useResolveEscalationMutation } from 'redux/services/questions';
import Filter from '../Filter';
import AnswerModal from '../AnswerModal';
import EmptyState from 'pages/Integrations/Connector/Components/EmptyState';
import Card from '../QuestionCard';
import { getDateForSelectedOption } from 'pages/Questions/utils';
import {
  DATE_DROPDOWN_VALUES,
  NAVBAR_ROUTES,
  QUESTIONS_PAGE_TABS,
  VIEW_TYPE
} from 'utils/constants';
import styles from '../../styles.module.scss';
import ViewToggle from 'components/ViewToggle';
import Table from 'components/NewTable';
import Pagination from 'components/Pagination';
import { DOCS_PER_PAGE } from 'utils/constants';

dayjs.extend(utc);
dayjs.extend(timezone);

const Tab = ({ setQuestionCount, tab, defaultSelectedQuestion }) => {
  const [filter, setFilter] = useState({
    q: '',
    dateFilterValue: DATE_DROPDOWN_VALUES.ALL,
    dateRange: {
      ...getDateForSelectedOption(DATE_DROPDOWN_VALUES.ALL)
    }
  });
  const screen767 = useMediaQuery('(max-width: 767px)');
  const [debouncedSearchTerm] = useDebouncedValue(filter.q, 500);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [resolveEscalation] = useResolveEscalationMutation();
  const [questions, setQuestions] = useState([]);
  const [viewType, setViewType] = useState(
    localStorage.getItem('questions.viewType') || VIEW_TYPE.LIST
  );
  const [page, setPage] = useState(1);

  const {
    data: { questions: questionsData, hasNext, totalAnswers } = { questions: [] },
    isLoading,
    isFetching
  } = useFetchQuestionsQuery(
    {
      page: page,
      itemsPerPage: DOCS_PER_PAGE,
      type: tab === QUESTIONS_PAGE_TABS.NEEDS_ATTENTION ? 'escalated' : 'others',
      origin: 'escalations',
      search: debouncedSearchTerm || undefined,
      dateRangeStart: filter?.dateRange?.start || undefined,
      dateRangeEnd: filter?.dateRange?.end || undefined,
      timezone: filter?.dateRange?.end ? dayjs.tz.guess() : undefined
    },
    {
      skip:
        filter?.dateFilterValue === DATE_DROPDOWN_VALUES.CUSTOM &&
        (filter?.dateRange?.start === null || filter?.dateRange?.end == null)
    }
  );

  useEffect(() => {
    if (!isFetching) {
      setQuestions(questionsData);
    }
    if (tab === QUESTIONS_PAGE_TABS.NEEDS_ATTENTION) {
      setQuestionCount((prev) => ({ ...prev, escalatedCount: totalAnswers }));
    } else {
      setQuestionCount((prev) => ({ ...prev, nonEscalatedCount: totalAnswers }));
    }
  }, [questionsData, page]);

  useEffect(() => {
    // If defaultSelectedQuestion is present, then open the modal
    if (defaultSelectedQuestion) {
      setSelectedQuestion({
        id: defaultSelectedQuestion,
        showPrev: false,
        showNext: false
      });
      window.history.pushState(null, null, NAVBAR_ROUTES.QUESTIONS);
    }
  }, [defaultSelectedQuestion]);

  const handleResolve = async (payload, closeModal = true) => {
    const { answer, promptId, resolveQuestion } = payload;
    const response = await resolveEscalation({ body: { answer, resolveQuestion }, promptId });
    if (response?.data?.ok) {
      toast.success(
        closeModal ? `Resolved and moved to 'Everything else' tab` : 'Escalation Resolved'
      );
    }
    closeModal && setSelectedQuestion(null);
  };

  return (
    <>
      {selectedQuestion?.id ? (
        <AnswerModal
          opened={true}
          selectedQuestion={selectedQuestion}
          onClose={() => setSelectedQuestion(null)}
          tab={tab}
          handleResolve={handleResolve}
          handleNavigation={(i) => {
            setSelectedQuestion({
              id: questions[i]._id,
              prevIndex: i - 1,
              nextIndex: i + 1,
              showPrev: i > 0,
              showNext: i < questions.length - 1
            });
          }}
        />
      ) : null}
      <Flex align={screen767 ? 'center' : 'end'} justify='space-between' mt={10}>
        <Filter filter={filter} setFilter={setFilter} />
        <ViewToggle
          viewType={viewType}
          setViewType={setViewType}
          setPage={setPage}
          pageName='questions'
        />
      </Flex>
      {tab === QUESTIONS_PAGE_TABS.NEEDS_ATTENTION ? (
        <Text size='xs' mt={20}>
          <b>Note:</b> when Chai answers a question incorrectly and a user clicks &apos;Ask admins
          for help,&apos; the question is displayed here. This can be from a public or private
          channel, group DM, or Chai&apos;s DM. When an admin answers or resolves the question, it
          is moved to the &apos;Everything else&apos; tab.
        </Text>
      ) : (
        <Text size='xs' mt={20}>
          <b>Note:</b> questions asked in public channels and the web dashboard are displayed here.
          Questions asked in private channels, group DMs and Chai&apos;s DM are only displayed here
          if a user clicks &apos;Ask admins for help&apos; and an admin answers or resolves the
          question.
        </Text>
      )}

      <div>
        {questions?.length ? (
          <div>
            <Pagination page={page} setPage={setPage} totalCount={totalAnswers}>
              {viewType === VIEW_TYPE.LIST ? (
                <Table
                  headers={['Date', 'User', 'Question', 'Response']}
                  items={questions}
                  itemClickHandler={(row) => {
                    const id = row._id;
                    setSelectedQuestion({
                      id,
                      prevIndex: id - 1,
                      nextIndex: id + 1,
                      showPrev: id > 0,
                      showNext: id < questions.length - 1
                    });
                  }}
                  rowType={'question'}
                />
              ) : (
                <>
                  <Grid mt={10} gutter='lg'>
                    {questions.map((d, i) => (
                      <Grid.Col
                        key={'grid' + i}
                        span={screen767 ? 12 : 4}
                        display='flex'
                        style={{
                          justifyContent: 'space-evenly'
                        }}
                      >
                        <Card
                          data={d}
                          onClick={(id) => {
                            setSelectedQuestion({
                              id,
                              prevIndex: id - 1,
                              nextIndex: id + 1,
                              showPrev: id > 0,
                              showNext: id < questions.length - 1
                            });
                          }}
                        />
                      </Grid.Col>
                    ))}
                    {isLoading || isFetching ? (
                      <Grid.Col
                        span={screen767 ? 12 : 4}
                        display='flex'
                        style={{ justifyContent: 'space-evenly' }}
                      >
                        <Skeleton height='100%' width='100%' />
                      </Grid.Col>
                    ) : null}
                  </Grid>
                  {(isLoading || isFetching) && (questions.length % 3 == 0 || screen767) ? (
                    <Skeleton
                      height={screen767 ? '50%' : '40%'}
                      width={screen767 ? '100%' : '33%'}
                    />
                  ) : null}
                </>
              )}
            </Pagination>
          </div>
        ) : isLoading ? (
          <div className={styles.loadingState}>
            <Loader />
          </div>
        ) : (
          <EmptyState
            hideButton={true}
            text={
              tab === QUESTIONS_PAGE_TABS.NEEDS_ATTENTION
                ? 'When a teammate asks admins for help, their question will be displayed here as an issue. You have no issues needing attention at the moment.'
                : 'Things look quiet here. When your colleagues ask Chai questions, they will appear here.'
            }
            textStyles={
              tab === QUESTIONS_PAGE_TABS.NEEDS_ATTENTION
                ? styles.needAttentionEmptyText
                : styles.othersEmptyText
            }
          />
        )}
      </div>
    </>
  );
};

Tab.propTypes = {
  tab: PropTypes.string,
  defaultSelectedQuestion: PropTypes.string,
  setQuestionCount: PropTypes.func.isRequired
};

export default Tab;
