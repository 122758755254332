import PropTypes from 'prop-types';
import { Button, Flex, Image, Text, Title } from '@mantine/core';
import Hourglass from 'assets/onboarding/hourglass.svg';
import CloseIcon from 'assets/icons/cross-popover.svg';
import styles from './styles.module.scss';

const FirstConnectionBanner = ({ trainingDuration, setShowBanner }) => {
  return (
    <Flex gap={24} justify='center' align='center' className={styles.wrapper}>
      <Image src={Hourglass} w={48} h={48} />
      <Flex gap={4} direction='column' className={styles.textContainer}>
        <Title className={styles.bannerTitle}>
          Chai is absorbing information from your sources. This usually takes {trainingDuration}.
        </Title>
        <Text className={styles.bannerText}>
          You can ask him questions but it may not be able to answer them until it has completed
          absorbing your information.
        </Text>
      </Flex>
      <Button onClick={() => setShowBanner(false)} className={styles.bannerBtn}>
        <Image src={CloseIcon} w={18} h={18} />
      </Button>
    </Flex>
  );
};

FirstConnectionBanner.propTypes = {
  trainingDuration: PropTypes.string.isRequired,
  setShowBanner: PropTypes.func.isRequired
};

export default FirstConnectionBanner;
