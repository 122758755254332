import { bool, node } from 'prop-types';
import classnames from 'classnames';
import styles from './FooterConfirmationBar.module.scss';

const FooterConfirmationBar = ({ children, show }) => {
  return (
    <div
      data-testid='footerconfirmationbar-text'
      className={classnames(styles.container, { [styles.show]: show })}
    >
      {children}
    </div>
  );
};

FooterConfirmationBar.propTypes = {
  children: node.isRequired,
  show: bool
};

FooterConfirmationBar.defaultProps = {
  show: false
};

export default FooterConfirmationBar;
