import { createSlice } from '@reduxjs/toolkit';
import { produce } from 'immer';

const initialState = {
  isLoading: false,
  training: false,
  ai: true,
  autoAnswer: {
    enabled: true,
    autoEnable: true,
    channels: []
  }
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    saveSettings: (state, action) => {
      return produce(state, (draftState) => {
        draftState.training = action.payload.training;
        draftState.ai = action.payload.ai;
        draftState.autoAnswer = { ...state.autoAnswer, ...action.payload.autoAnswer };
        draftState.selectedPersona = action.payload.selectedPersona;
      });
    },
    isSettingsLoading: (state, action) => {
      return produce(state, (draftState) => {
        draftState.isLoading = action.payload;
      });
    },
    saveAutoAnswerChannels: (state, action) => {
      return produce(state, (draftState) => {
        draftState.autoAnswer.channels = action.payload;
      });
    }
  }
});

export const { saveSettings, isSettingsLoading, saveAutoAnswerChannels } = settingsSlice.actions;

export default settingsSlice.reducer;
