import PropTypes from 'prop-types';
import { SimpleGrid, Skeleton, Pagination, Flex, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import SourceGridItem from './SourceGridItem';
import { selectSourceIcon } from 'pages/Integrations/utils';
import { maybePluralize } from 'utils/utilities';
import { DOCS_PER_PAGE } from 'utils/constants';
import styles from 'components/Table/styles.module.scss';

const SourceGrid = (props) => {
  const {
    items,
    setTotalItems,
    hideCreatedAt,
    hideLastTrained,
    hideUploadedBy,
    showManageResources,
    hideDisconnect,
    domainTitle,
    sourceIcon,
    handleSitemapManageSource,
    pagination,
    pageCount,
    isLoading,
    showHoverEffect,
    hideRetrainNow,
    hideManageAutoRetrain,
    messageSourceDeleteModal,
    showManageSourceForWeb,
    showManageSitemapSources,
    showEditName,
    metaPayload,
    selectedSources,
    handleCheckboxCta,
    showCheckbox,
    isAllSourcesSelected,
    checkboxTooltipLabels,
    totalCount
  } = props;

  const [itemsPerPage, setItemsPerPage] = useState(items.slice(0, DOCS_PER_PAGE));
  const [internalPageCount, setInternalPageCount] = useState(
    Math.ceil(items.length / DOCS_PER_PAGE)
  );
  const [activePage, setActivePage] = useState(1);

  const handlePageChange = (page) => {
    setActivePage(page);
    const startIndex = (page - 1) * DOCS_PER_PAGE;
    const endIndex = startIndex + DOCS_PER_PAGE;
    setItemsPerPage(items.slice(startIndex, endIndex));
  };

  useEffect(() => {
    handlePageChange(activePage);
    setInternalPageCount(Math.ceil(items.length / DOCS_PER_PAGE));
  }, [items]);

  return (
    <div>
      <SimpleGrid>
        {isLoading ? (
          <Skeleton height={300} />
        ) : (
          <>
            {(pagination?.active ? items : itemsPerPage).map((item) => {
              return (
                <SourceGridItem
                  key={item.id}
                  item={item}
                  hideCreatedAt={hideCreatedAt}
                  hideLastTrained={hideLastTrained}
                  hideUploadedBy={hideUploadedBy}
                  showManageResources={showManageResources}
                  hideDisconnect={hideDisconnect}
                  domainTitle={domainTitle}
                  sourceIcon={selectSourceIcon(item?.type) || item?.sourceIcon || sourceIcon}
                  showManageSitemapSources={showManageSitemapSources}
                  handleSitemapManageSource={handleSitemapManageSource}
                  showHoverEffect={showHoverEffect}
                  hideRetrainNow={hideRetrainNow}
                  hideManageAutoRetrain={hideManageAutoRetrain}
                  messageSourceDeleteModal={messageSourceDeleteModal}
                  showManageSourceForWeb={showManageSourceForWeb}
                  showEditName={showEditName}
                  metaPayload={metaPayload}
                  setTotalItems={setTotalItems}
                  selectedSources={selectedSources}
                  isAllSourcesSelected={isAllSourcesSelected}
                  handleCheckboxCta={handleCheckboxCta}
                  showCheckbox={showCheckbox}
                  checkboxTooltipLabels={checkboxTooltipLabels}
                  hideActionMenu={selectedSources.length || isAllSourcesSelected}
                />
              );
            })}
          </>
        )}
      </SimpleGrid>

      <Flex className={styles.paginationWrapper}>
        <Pagination
          total={pageCount || internalPageCount}
          onChange={pagination?.setPage || handlePageChange}
          value={pagination?.active || activePage}
        />
        {totalCount ? (
          <Text>
            {(pagination ? pagination.active : activePage) === Math.ceil(totalCount / DOCS_PER_PAGE)
              ? totalCount
              : itemsPerPage.length}
            &nbsp;of {totalCount} {maybePluralize(totalCount, 'result')}
          </Text>
        ) : (
          <Text>
            {DOCS_PER_PAGE * (activePage - 1) + itemsPerPage.length} of {items.length}&nbsp;
            {maybePluralize(items.length, 'result')}
          </Text>
        )}
      </Flex>
    </div>
  );
};

SourceGrid.propTypes = {
  totalItems: PropTypes.array,
  items: PropTypes.array,
  setTotalItems: PropTypes.func,
  hideCreatedAt: PropTypes.bool,
  checkboxTooltipLabels: PropTypes.array,
  hideLastTrained: PropTypes.bool,
  hideUploadedBy: PropTypes.bool,
  hideDisconnect: PropTypes.bool,
  showManageResources: PropTypes.bool,
  domainTitle: PropTypes.string,
  sourceIcon: PropTypes.string,
  handleSitemapManageSource: PropTypes.func,
  pagination: PropTypes.shape({
    active: PropTypes.number,
    setPage: PropTypes.func
  }),
  pageCount: PropTypes.number,
  isLoading: PropTypes.bool,
  selectedSources: PropTypes.array,
  isAllSourcesSelected: PropTypes.bool,
  handleCheckboxCta: PropTypes.func,
  showCheckbox: PropTypes.bool,
  showHoverEffect: PropTypes.bool,
  hideRetrainNow: PropTypes.bool,
  hideManageAutoRetrain: PropTypes.bool,
  messageSourceDeleteModal: PropTypes.bool,
  showManageSourceForWeb: PropTypes.bool,
  showManageSitemapSources: PropTypes.bool,
  showEditName: PropTypes.bool,
  metaPayload: PropTypes.shape({
    provider: PropTypes.string,
    params: PropTypes.shape({
      teamId: PropTypes.string,
      page: PropTypes.number,
      limit: PropTypes.number,
      q: PropTypes.string
    })
  }).isRequired,
  totalCount: PropTypes.number
};

SourceGrid.defaultProps = {
  showManageSitemapSources: false,
  handleSitemapManageSource: () => null,
  totalItems: [],
  setTotalItems: () => null,
  pagination: {},
  isLoading: false,
  hideRetrainNow: false,
  hideManageAutoRetrain: false,
  showCheckbox: false,
  handleCheckboxCta: () => null,
  selectedSources: [],
  isAllSourcesSelected: false,
  checkboxTooltipLabels: []
};

export default SourceGrid;
