import { AES, enc } from 'crypto-js';

const FLAG = 'token';

export default {
  decryptToken: (encToken) => {
    try {
      const encryptionKey = import.meta.env.VITE_DEFAULT_KEY;
      const data = AES.decrypt(decodeURIComponent(encToken), encryptionKey);
      const [status, key, token, platform, inviteCode] = data.toString(enc.Utf8).split('|');
      return { status, key, token, platform, inviteCode };
    } catch {
      return { status: 'INVALID', token: null };
    }
  },
  setToken: (token) => localStorage.setItem(FLAG, decodeURIComponent(token)),
  deleteToken: () => localStorage.removeItem(FLAG),
  clearStorage: () => localStorage.clear(),
  getToken: () => {
    try {
      let flag = localStorage.getItem(FLAG);
      const encryptionKey = import.meta.env.VITE_DEFAULT_KEY;
      let data;
      if (!flag) {
        const params = new URLSearchParams(window.location.search);
        flag = params.get(FLAG);
        if (!flag) {
          return null;
        }
        data = AES.decrypt(decodeURIComponent(flag), encryptionKey);
      } else {
        data = AES.decrypt(flag, encryptionKey);
      }
      const [status, key, token] = data.toString(enc.Utf8).split('|');
      if (status === 'VALID' && key === import.meta.env.VITE_DEFAULT_KEY) {
        return token;
      }
      return null;
    } catch {
      return null;
    }
  },
  hasToken: () => localStorage.getItem(FLAG),
  getPlatform: () => {
    try {
      const flag = localStorage.getItem(FLAG);
      if (!flag) {
        return null;
      }
      const encryptionKey = import.meta.env.VITE_DEFAULT_KEY;
      const data = AES.decrypt(flag, encryptionKey);
      const [status, key, , platform] = data.toString(enc.Utf8).split('|');
      if (status === 'VALID' && key === import.meta.env.VITE_DEFAULT_KEY) {
        return platform;
      }
      return null;
    } catch {
      return null;
    }
  },
  set: (key, value, expiry) => {
    if (expiry) {
      localStorage.setItem(key, JSON.stringify({ value, expiry }));
    } else {
      localStorage.setItem(key, value);
    }
  },
  get: (key) => localStorage.getItem(key),
  clear: (key) => {
    localStorage.removeItem(key);
  }
};
