import dayjs from 'dayjs';
import { PROVIDERS, TRAINING_TYPE, GOOGLE, TRUTO_CONNECTORS_NAME_MAPPING } from 'utils/constants';
import PdfIcon from 'assets/icons/pdf_icon.svg';
import CSVIcon from 'assets/icons/csv_icon.svg';
import DocIcon from 'assets/icons/doc_icon.svg';
import XlsxIcon from 'assets/icons/xlsx_icon.svg';
import PPTXIcon from 'assets/icons/pptx_icon.svg';
import GoogleSlidesIcon from 'assets/source-icons/google-slides-icon.svg';
import GoogleSheetsIcon from 'assets/source-icons/google-sheets-icon.svg';
import GoogleDocsIcon from 'assets/source-icons/google-docs-icon.svg';
import WordIcon from 'assets/source-icons/docx-icon.svg';
import ExcelIcon from 'assets/source-icons/xlsx-icon.svg';
import PowerPointIcon from 'assets/source-icons/ppt-icon.svg';
import PdfDocIcon from 'assets/source-icons/pdf-icon.svg';
import CsvDocIcon from 'assets/source-icons/csv-icon.svg';
import { DOCUMENT_SOURCE_TYPES } from 'utils/constants';
import { CONNECTOR_LIST } from 'pages/ControlPanel/constants';
import { formatDate } from 'utils/formatters';

export const providerTitle = (provider) => {
  return CONNECTOR_LIST.find((conn) => conn.name === provider)?.title || provider;
};

export const subtextProvider = (provider) => {
  switch (provider) {
    case PROVIDERS.JIRA:
      return '(Jira Software, Jira Service Management)';
    case PROVIDERS.CUSTOM_SOURCES:
      return '(.doc, .docx, .pdf)';
    default:
      return '';
  }
};

export const isRetrainDisabled = (date, provider) => {
  if (!date) {
    return { isDisabled: false, message: 'Date not provided.' };
  }

  const threshold = provider === 'slack' ? 12 : 1;
  const timeDifference = dayjs().diff(date, 'hour');

  const remainingTimeInHours = threshold - timeDifference;

  const isDisabled = timeDifference < threshold;

  const message = isDisabled
    ? provider === 'slack'
      ? `You trained Chat Aid on this source less than 12 hours ago. You can retrain him in ${remainingTimeInHours} hours`
      : 'You refreshed less than an hour ago. Try again later.'
    : null;

  return { isDisabled, message };
};

export const returnIcon = (fileType) => {
  switch (fileType) {
    case GOOGLE.MIME_TYPES.PDF:
      return { icon: PdfIcon, alt: 'pdf' };
    case GOOGLE.MIME_TYPES.CSV:
      return { icon: CSVIcon, alt: 'csv' };
    case GOOGLE.MIME_TYPES.XLSX:
    case GOOGLE.MIME_TYPES.SHEET:
      return { icon: XlsxIcon, alt: 'xlsx' };
    case GOOGLE.MIME_TYPES.DOCX:
    case GOOGLE.MIME_TYPES.DOC:
      return { icon: DocIcon, alt: 'docx' };
    case GOOGLE.MIME_TYPES.PPTX:
    case GOOGLE.MIME_TYPES.SLIDE:
      return { icon: PPTXIcon, alt: 'pptx' };
    default:
      return { icon: CSVIcon, alt: 'any' };
  }
};

export const SOURCE_TYPES = {
  MESSAGES: 'message',
  CHANNELS: 'channel'
};

export const DOCUMENT = {
  INVALID: 'file-invalid-type',
  LARGE_FILE: 'file-too-large'
};

export const WIKI_BANNER = 'wiki_banner';

export function truncateName(name, truncateLength = 100) {
  if (!name?.length || name.length <= truncateLength) {
    return name;
  }

  return name.substring(0, truncateLength) + '...';
}

export function limitLearnMoreClickHandler() {
  window.open(
    'https://support.springworks.in/portal/en/kb/articles/training-limits-on-free-and-paid-plans',
    '_blank'
  );
}

/**
 * Constructs the payload for Truto sources for add source request
 * @param {Object} params - params
 * @param {Object} params.response - response received from rapid form
 * @param {string} params.provider - truto provider for which source is added
 * @param {Object} params.user - user who added
 * @returns {Object} The payload object
 */
export const getTrutoAddSourcePayload = ({ response, provider, user }) => {
  try {
    let payload = [];

    const {
      selected_cloud,
      workspace_id: workspace,
      collection_id: collection,
      collections,
      drive_items
    } = response;

    let domain;
    if (workspace) {
      domain = { id: workspace.value, name: workspace.label };
    } else if (selected_cloud) {
      // For Jira, site info is stored under selected_cloud in rapidForm
      domain = { id: selected_cloud.value, url: selected_cloud.label };
    }
    const drive = collection ? { id: collection.value, name: collection.label } : undefined;

    // If user has selected multiple sources, collections would be array, else it would be an object
    if (Array.isArray(collections) && collections.length) {
      payload.push(
        ...collections.map((document) => ({
          provider,
          documentId: document?.value || `${provider}_${user._id}`,
          documentName:
            document?.label || `${user.name}'s ${TRUTO_CONNECTORS_NAME_MAPPING[provider]}`,
          ...((provider === PROVIDERS.NOTION || provider === PROVIDERS.ZENDESK_HELP_CENTER) && {
            type: document?.subText
          }),
          domain
        }))
      );
    } else if (collections?.value) {
      payload.push({
        provider,
        documentId: collections?.value || `${provider}_${user._id}}`,
        documentName:
          collections?.label || `${user.name}'s ${TRUTO_CONNECTORS_NAME_MAPPING[provider]}`,
        ...((provider === PROVIDERS.NOTION || provider === PROVIDERS.ZENDESK_HELP_CENTER) && {
          type: collections?.subText
        }),
        domain
      });
    }

    // If user has selected multiple drive-items, drive_items would be array, else it would be an object
    if (Array.isArray(drive_items) && drive_items.length) {
      payload.push(
        ...drive_items
          .filter((document) => document.subText === 'file')
          .map((document) => ({
            provider,
            documentId: document?.value || `${provider}_${user._id}`,
            documentName:
              document?.label || `${user.name}'s ${TRUTO_CONNECTORS_NAME_MAPPING[provider]}`,
            domain,
            ...(drive && { drive })
          }))
      );
    } else if (drive_items?.value && drive_items.subText === 'file') {
      payload.push({
        provider,
        documentId: drive_items?.value || `${provider}_${user._id}`,
        documentName:
          drive_items?.label || `${user.name}'s ${TRUTO_CONNECTORS_NAME_MAPPING[provider]}`,
        domain,
        ...(drive && { drive })
      });
    }
    return payload;
  } catch {
    return [];
  }
};

export function retrainFailureTooltip(status, trainingType, lastTrainingAttemptedAt) {
  if (!status || !trainingType) {
    return;
  }

  if (status === 'error') {
    if (trainingType === TRAINING_TYPE.FORCE_RETRAIN) {
      return `Retrain failed on ${formatDate(lastTrainingAttemptedAt, 'DD MMM YYYY')}`;
    }
    if (trainingType === TRAINING_TYPE.AUTOMATIC_RETRAIN) {
      return `Auto-retrain failed on ${formatDate(lastTrainingAttemptedAt, 'DD MMM YYYY')}`;
    }
  }
}

export const selectSourceIcon = (type) => {
  switch (type) {
    case DOCUMENT_SOURCE_TYPES.GOOGLE_DOC:
      return GoogleDocsIcon;
    case DOCUMENT_SOURCE_TYPES.GOOGLE_SHEET:
      return GoogleSheetsIcon;
    case DOCUMENT_SOURCE_TYPES.GOOGLE_SLIDE:
      return GoogleSlidesIcon;
    case DOCUMENT_SOURCE_TYPES.GOOGLE_DOCX:
    case DOCUMENT_SOURCE_TYPES.DOCX:
      return WordIcon;
    case DOCUMENT_SOURCE_TYPES.GOOGLE_XLSX:
    case DOCUMENT_SOURCE_TYPES.XLSX:
      return ExcelIcon;
    case DOCUMENT_SOURCE_TYPES.GOOGLE_PPT:
    case DOCUMENT_SOURCE_TYPES.PPTX:
      return PowerPointIcon;
    case DOCUMENT_SOURCE_TYPES.GOOGLE_PDF:
    case DOCUMENT_SOURCE_TYPES.PDF:
      return PdfDocIcon;
    case DOCUMENT_SOURCE_TYPES.GOOGLE_CSV:
    case DOCUMENT_SOURCE_TYPES.CSV:
      return CsvDocIcon;
    default:
      return null;
  }
};
