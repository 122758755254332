import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Loader } from '@mantine/core';
import Layout from 'components/Layout';
import styles from './styles.module.scss';

const Analytics = () => {
  const { user, loading: isUserLoading } = useSelector((state) => state.user);
  const [embedHtml, setEmbedHtml] = useState('');
  const mixpanelReportURL = user?.analyticsUrl;

  useEffect(() => {
    const fetchEmbedData = async () => {
      if (mixpanelReportURL) {
        setEmbedHtml(
          "<iframe width='100%' height='100%' src='" +
            mixpanelReportURL +
            "' frameborder='0' allowfullscreen></iframe>"
        );
      }
    };

    fetchEmbedData();
  }, [mixpanelReportURL]);

  if (isUserLoading) {
    return (
      <Layout pageTitle='Activity logs'>
        <div className={styles.loaderContainer}>
          <Loader />
        </div>
      </Layout>
    );
  }

  return (
    <Layout className={styles.analyticsWrapper} pageTitle='Activity logs'>
      <div className={styles.iframeContainer} dangerouslySetInnerHTML={{ __html: embedHtml }} />
    </Layout>
  );
};

export default Analytics;
