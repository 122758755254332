import { toast } from 'react-toastify';
import { current } from '@reduxjs/toolkit';
import cloneDeep from 'lodash.clonedeep';
import { SETTINGS, TEAMS, TEAM, END_POINT } from 'utils/apiEndpoints';
import { handleApiError } from 'utils/helpers';
import api from './api';
import { saveAutoAnswerChannels, saveSettings } from '../features/settingsSlice';

const initialState = {
  teamsData: [],
  teamDetails: {},
  users: []
};

const settingsApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateSetting: build.mutation({
      query: (payload) => ({
        url: SETTINGS,
        method: 'PATCH',
        body: payload
      }),
      invalidatesTags: ['Settings'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;
          if (response.ok) {
            dispatch(saveSettings(response.data));
          } else {
            toast.error('Failed to update settings');
          }
          return Promise.resolve();
        } catch ({ error }) {
          handleApiError(error);
          return Promise.resolve();
        }
      }
    }),
    getSettings: build.query({
      query: () => ({
        url: SETTINGS,
        method: 'GET'
      }),
      providesTags: ['Settings'],
      transformResponse: (response) => {
        if (response.ok) {
          return response.data;
        } else {
          toast.error('Failed to fetch organisation settings.');
        }
      },
      transformErrorResponse: (error) => handleApiError(error)
    }),

    getTeams: build.query({
      query: () => ({
        url: TEAMS,
        params: { all: true }
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response.data;
        }
        toast.error('Failed to fetch teams.');
        return initialState.teamsData;
      },
      transformErrorResponse: (error) => {
        handleApiError(error);
        return initialState.teamsData;
      }
    }),
    // GET /team/:teamId endpoint
    getTeam: build.query({
      query: (teamId) => ({
        url: `${TEAM}/${teamId}`,
        params: { all: true }
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response.data;
        }
        toast.error('Failed to fetch team details.');
        return initialState.teamDetails;
      },
      transformErrorResponse: (error) => {
        handleApiError(error);
        return initialState.teamDetails;
      }
    }),

    // GET /team/users endpoint
    getUsers: build.query({
      query: (teamId) => ({
        url: `${TEAM}/users?${teamId ? `teamId=${teamId}` : ''}`,
        method: 'GET'
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response.users;
        }
        toast.error('Failed to fetch users');
        return initialState.users;
      },
      transformErrorResponse: (error) => {
        handleApiError(error);
        return initialState.users;
      }
    }),

    // POST /team endpoint
    createTeam: build.mutation({
      query: (body) => ({
        url: TEAM,
        method: 'POST',
        body
      }),
      // Manually update the cache to add the new automation
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;
          if (response.ok) {
            dispatch(
              api.util.updateQueryData('getTeams', undefined, (draft) => {
                let cacheData = current(draft);

                return { ...cacheData, teams: [...cacheData.teams, response.data] };
              })
            );

            toast.success('Created team successfully');
          } else {
            toast.error('Failed to add team');
          }
          return Promise.resolve();
        } catch ({ error }) {
          handleApiError(error);
          return Promise.resolve();
        }
      }
    }),

    // PATCH /team/:teamId endpoint
    updateTeam: build.mutation({
      query: ({ body, teamId }) => ({
        url: `${TEAM}/${teamId}`,
        method: 'PATCH',
        body
      }),
      // Manually update the cache to add the new automation
      async onQueryStarted(formData, { dispatch, queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;
          if (response.ok) {
            dispatch(
              api.util.updateQueryData('getTeam', formData.teamId, (draft) => {
                const teamDetails = current(draft);
                return { ...teamDetails, ...response.data };
              })
            );

            formData.body?.name &&
              dispatch(
                api.util.updateQueryData('getTeams', undefined, (draft) => {
                  const cacheData = current(draft);
                  let teamsCopy = cloneDeep(cacheData.teams);
                  const index = teamsCopy.findIndex((team) => team.teamId === formData.teamId);
                  teamsCopy[index].name = formData.body.name;
                  return { ...cacheData, teams: teamsCopy };
                })
              );

            formData.loggedInUser &&
              dispatch(
                api.util.updateQueryData('getTeams', undefined, (draft) => {
                  const cacheData = current(draft);
                  let teamsCopy = cloneDeep(cacheData.teams);
                  if (formData.loggedInUser === formData.body.adminsRemoved[0]) {
                    const newTeams = teamsCopy.filter((team) => team.teamId !== formData.teamId);
                    return { ...cacheData, teams: newTeams };
                  }

                  return { ...cacheData };
                })
              );

            dispatch(
              api.util.updateQueryData('getTeams', undefined, (draft) => {
                const cacheData = current(draft);
                let teamsCopy = cloneDeep(cacheData.teams);
                const index = teamsCopy.findIndex((team) => team.teamId === formData.teamId);
                teamsCopy[index] = {
                  ...teamsCopy[index],
                  ...response.data,
                  membersCount: response.data.members.length
                };
                return { ...cacheData, teams: teamsCopy };
              })
            );
          } else {
            toast.error('Failed to add team');
          }
          return Promise.resolve();
        } catch ({ error }) {
          handleApiError(error);
          return Promise.resolve();
        }
      }
    }),

    // DELETE /team/:teamId endpoint
    deleteTeam: build.mutation({
      query: (formData) => ({
        url: `${TEAM}/${formData.teamId}`,
        method: 'DELETE'
      }),
      // Manually update the cache to add the new automation
      async onQueryStarted({ teamId }, { dispatch, queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;

          if (response.ok) {
            dispatch(
              api.util.updateQueryData('getTeams', undefined, (draft) => {
                let cacheData = current(draft);
                const teams = cacheData.teams.filter((team) => team.teamId !== teamId);
                return { ...cacheData, teams };
              })
            );

            toast.success(`Deleted team ${response.teamName} successfully`);
          } else {
            toast.error(`Failed to delete team`);
          }
          return Promise.resolve();
        } catch ({ error }) {
          handleApiError(error);
          return Promise.resolve();
        }
      }
    }),
    getCompanyDetails: build.query({
      query: () => ({
        url: END_POINT.SETTINGS.COMPANY,
        method: 'GET'
      }),
      transformResponse: (response) => {
        if (response.ok) {
          return response.data;
        }
      },
      transformErrorResponse: (error) => handleApiError(error)
    }),
    updateCompanyDetails: build.mutation({
      query: (body) => {
        return {
          url: END_POINT.SETTINGS.COMPANY,
          method: 'PATCH',
          body
        };
      },
      async onQueryStarted({ name, logo }, { dispatch, queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;
          if (response.ok) {
            dispatch(
              api.util.updateQueryData('getCompanyDetails', undefined, (draft) => {
                let cacheData = { ...current(draft) };
                if (name) {
                  cacheData['name'] = name;
                }
                if (logo) {
                  cacheData['logo'] = URL.createObjectURL(logo);
                }
                return cacheData;
              })
            );
          } else {
            toast.error('Failed to updated company details');
          }
          return Promise.resolve();
        } catch ({ error }) {
          handleApiError(error);
          return Promise.resolve();
        }
      },
      invalidatesTags: ['User', 'OrganisationsList']
    }),
    deleteCompany: build.mutation({
      query: () => ({
        url: END_POINT.SETTINGS.DASHBOARD_ORGANISATION,
        method: 'DELETE'
      }),
      transformErrorResponse: (error) => handleApiError(error)
    }),
    updateUserDetails: build.mutation({
      query: (body) => ({
        url: END_POINT.SETTINGS.USER,
        method: 'PATCH',
        body
      }),
      transformErrorResponse: (error) => handleApiError(error),
      invalidatesTags: ['User']
    }),
    deleteAccount: build.mutation({
      query: (body) => ({
        url: END_POINT.SETTINGS.USER_ORGANISATION,
        method: 'DELETE',
        body
      }),
      transformErrorResponse: (error) => handleApiError(error)
    }),
    switchCompany: build.mutation({
      query: (body) => ({
        url: END_POINT.USER.SWITCH_ORGANISATION,
        method: 'POST',
        body
      }),
      transformErrorResponse: (error) => handleApiError(error)
    }),
    getAutoAnswerChannels: build.query({
      query: () => ({
        url: END_POINT.SETTINGS.AUTO_ANSWER_CHANNELS,
        method: 'GET'
      }),
      providesTags: ['Settings-AutoAnswerChannels'],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;
          if (response.ok) {
            dispatch(saveAutoAnswerChannels(response.data));
            return response.data;
          } else {
            toast.error('Failed to fetch auto-answer enabled channels');
          }
        } catch ({ error }) {
          handleApiError(error);
        }
      }
    }),
    updateAutoAnswerChannelSettings: build.mutation({
      query: (body) => ({
        url: END_POINT.SETTINGS.AUTO_ANSWER_CHANNELS,
        method: 'PATCH',
        body
      }),
      invalidatesTags: ['Settings-AutoAnswerChannels'],
      async onQueryStarted({ channels, autoAnswer }, { dispatch, queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;
          if (response.ok) {
            dispatch(saveAutoAnswerChannels(response.data));
            const operation = autoAnswer ? 'enabled' : 'disabled';
            const plural = channels.length > 1 ? `for ${channels.length} channels` : '';
            toast.success(`Auto-answer ${operation} ${plural}`);
          } else {
            toast.error('Failed to update auto-answer settings');
          }
          return Promise.resolve();
        } catch ({ error }) {
          handleApiError(error);
          return Promise.resolve();
        }
      }
    }),

    // Personas services
    createPersona: build.mutation({
      query: (body) => ({
        url: END_POINT.SETTINGS.PERSONA,
        method: 'POST',
        body
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;
          if (response.ok) {
            dispatch(
              api.util.updateQueryData('getSettings', undefined, (draft) => {
                let { personas, selectedPersona, ...rest } = cloneDeep(current(draft));
                personas = [...personas, response.data];
                if (body.activate) {
                  selectedPersona = response.data._id;
                }
                return { ...rest, personas, selectedPersona };
              })
            );
            toast.success(`Persona${body.activate ? ' selected ' : ' created '}successfully`);
          } else {
            toast.error('Failed creating persona');
          }
          return Promise.resolve();
        } catch ({ error }) {
          handleApiError(error);
          return Promise.resolve();
        }
      }
    }),
    updatePersona: build.mutation({
      query: ({ id, ...body }) => ({
        url: `${END_POINT.SETTINGS.PERSONA}/${id}`,
        method: 'PATCH',
        body
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;
          if (response.ok) {
            dispatch(
              api.util.updateQueryData('getSettings', undefined, (draft) => {
                let { personas, selectedPersona, ...rest } = cloneDeep(current(draft));
                personas = personas.map((persona) => {
                  if (persona._id === body.id) {
                    return { ...persona, ...response.data };
                  }
                  return persona;
                });
                if (body.activate) {
                  selectedPersona = response.data._id;
                }
                return { ...rest, personas, selectedPersona };
              })
            );
            toast.success(`Persona${body.activate ? ' selected ' : ' saved '}successfully`);
          } else {
            toast.error('Failed saving persona');
          }
          return Promise.resolve();
        } catch ({ error }) {
          handleApiError(error);
          return Promise.resolve();
        }
      }
    }),
    deletePersona: build.mutation({
      query: (id) => ({
        url: `${END_POINT.SETTINGS.PERSONA}/${id}`,
        method: 'DELETE'
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;
          if (response.ok) {
            dispatch(
              api.util.updateQueryData('getSettings', undefined, (draft) => {
                let { personas, selectedPersona, ...rest } = cloneDeep(current(draft));
                personas = personas.filter((persona) => persona._id !== id);
                if (selectedPersona === id) {
                  selectedPersona = personas.find(
                    (p) => p.name === 'Default' && p.scope === 'global'
                  )._id;
                }
                return { ...rest, personas, selectedPersona };
              })
            );
            toast.success('Persona deleted successfully');
          } else {
            toast.error('Failed deleting persona');
          }
          return Promise.resolve();
        } catch ({ error }) {
          handleApiError(error);
          return Promise.resolve();
        }
      }
    }),
    createCustomApi: build.mutation({
      query: (body) => ({
        url: END_POINT.SETTINGS.CUSTOM_API,
        method: 'POST',
        body
      }),
      transformErrorResponse: (error) => handleApiError(error)
    }),
    getCustomApis: build.query({
      query: () => ({
        url: `${END_POINT.SETTINGS.CUSTOM_APIS}`,
        method: 'GET'
      }),
      providesTags: ['CustomApis'],
      transformErrorResponse: (error) => handleApiError(error)
    }),
    getCustomApi: build.query({
      query: (id) => ({
        url: `${END_POINT.SETTINGS.CUSTOM_API}/${id}`,
        method: 'GET'
      }),
      providesTags: ['CustomApi'],
      transformErrorResponse: (error) => handleApiError(error)
    }),
    updateCustomApi: build.mutation({
      query: ({ id, data }) => ({
        url: `${END_POINT.SETTINGS.CUSTOM_API}/${id}`,
        method: 'PATCH',
        body: data
      }),
      async onQueryStarted(formData, { dispatch, queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;
          if (response?.ok) {
            dispatch(
              api.util.updateQueryData('getCustomApi', formData.id, (draft) => {
                let cacheData = current(draft);
                cacheData = response;
                return cacheData;
              })
            );

            dispatch(
              api.util.updateQueryData('getCustomApis', undefined, (draft) => {
                let { data } = cloneDeep(current(draft));
                data = data.map((api) =>
                  api.id === formData.id ? { ...api, name: response.data?.name } : api
                );
                return { data };
              })
            );

            toast.success('Custom API updated successfully');
          }
          return Promise.resolve();
        } catch ({ error }) {
          handleApiError(error);
          return Promise.resolve();
        }
      }
    }),
    deleteCustomApi: build.mutation({
      query: (id) => ({
        url: `${END_POINT.SETTINGS.CUSTOM_API}/${id}`,
        method: 'DELETE'
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;
          if (response?.ok) {
            dispatch(
              api.util.updateQueryData('getCustomApis', undefined, (draft) => {
                let { data } = cloneDeep(current(draft));
                data = data.filter((api) => api.id !== id);
                return { data };
              })
            );
            toast.success('API key deleted successfully');
          } else {
            toast.error('Failed to delete custom API');
          }
          return Promise.resolve();
        } catch ({ error }) {
          toast.error(error);
          return Promise.resolve();
        }
      },
      transformErrorResponse: (error) => handleApiError(error)
    }),
    updateCustomApiKeys: build.mutation({
      query: ({ accessId, operation, id }) => ({
        url: `${END_POINT.SETTINGS.CUSTOM_API}/key/${accessId}?operation=${operation}${
          id !== null && id !== undefined ? `&id=${id}` : ''
        }`,
        method: 'PATCH'
      }),
      async onQueryStarted(formData, { dispatch, queryFulfilled }) {
        try {
          const { data: response = {} } = await queryFulfilled;
          if (response?.ok) {
            dispatch(
              api.util.updateQueryData('getCustomApi', formData.accessId, (draft) => {
                let { data } = cloneDeep(current(draft));
                if (formData.operation === 'create') {
                  const newToken = {
                    ...response.data,
                    createdAt: new Date().toISOString()
                  };
                  let accessTokens = [...data.accessTokens, newToken];
                  return { data: { ...data, accessTokens } };
                } else if (formData.operation === 'delete') {
                  data.accessTokens = data.accessTokens.filter((token) => token.id !== formData.id);
                }
                return { data };
              })
            );
            if (formData.operation === 'create') {
              toast.success('API key generated successfully');
            } else {
              toast.success('API key deleted successfully');
            }
          }
          return Promise.resolve();
        } catch ({ error }) {
          handleApiError(error);
          return Promise.resolve();
        }
      },
      transformErrorResponse: (error) => handleApiError(error)
    }),
    getConnectionStatus: build.query({
      query: (provider) => ({
        url: `${END_POINT.SETTINGS.CONNECTION_STATUS}/${provider}`,
        method: 'GET'
      }),
      providesTags: ['Settings-ConnectionStatus'],
      transformErrorResponse: (error) => handleApiError(error)
    }),
    updateConnectionStatusToInProgress: build.mutation({
      query: (provider) => ({
        url: `${END_POINT.SETTINGS.CONNECTION_STATUS}/${provider}`,
        method: 'PATCH'
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled();
          return Promise.resolve();
        } catch {
          return Promise.resolve();
        }
      }
    })
  })
});

export const {
  useUpdateSettingMutation,
  useGetSettingsQuery,
  useGetTeamsQuery,
  useGetTeamQuery,
  useCreateTeamMutation,
  useGetUsersQuery,
  useUpdateTeamMutation,
  useDeleteTeamMutation,
  useLazyGetTeamsQuery,
  useUpdateUserDetailsMutation,
  useDeleteAccountMutation,
  useGetCompanyDetailsQuery,
  useUpdateCompanyDetailsMutation,
  useSwitchCompanyMutation,
  useDeleteCompanyMutation,
  useGetAutoAnswerChannelsQuery,
  useUpdateAutoAnswerChannelSettingsMutation,
  useCreatePersonaMutation,
  useUpdatePersonaMutation,
  useDeletePersonaMutation,
  useCreateCustomApiMutation,
  useGetCustomApisQuery,
  useLazyGetCustomApiQuery,
  useUpdateCustomApiMutation,
  useDeleteCustomApiMutation,
  useFetchCustomApiWikisQuery,
  useUpdateCustomApiKeysMutation,
  useGetConnectionStatusQuery,
  useUpdateConnectionStatusToInProgressMutation
} = settingsApi;

export default settingsApi;
