import PropTypes from 'prop-types';
import { Image, Button } from '@mantine/core';
import EmptyStateLogo from 'assets/empty-state-logo.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus-o.svg';
import styles from './styles.module.scss';

const EmptyState = ({ handleClick }) => (
  <div className={styles.container}>
    <Image src={EmptyStateLogo} alt='Empty State Logo' className={styles.image} />
    <div className={styles.headerText}>Chai behaves more contextually in teams</div>
    <div className={styles.subHeaderText}>
      Create a team for your engineering function such that Chai absorbs information from sources
      relevant only to your engineering function.
    </div>
    <div className={styles.subHeaderText}>
      When an employee from your new team asks Chai a question, it will try to find the most
      suitable answer from both your company and team wikis.
    </div>
    <Button className='primaryBtn' leftSection={<PlusIcon />} onClick={handleClick}>
      Create team
    </Button>
  </div>
);

EmptyState.propTypes = {
  handleClick: PropTypes.func.isRequired
};

export default EmptyState;
