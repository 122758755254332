import { Flex, Tooltip } from '@mantine/core';
import SVGIcon, { ICONS_LIST } from 'components/SVGIcon';
import styles from './constant_objects_styles.module.scss';

export const INTEGRATIONS_TABLE_HEADERS = [
  'Source',
  <Flex key='integration_table_header'>
    Last trained
    <Tooltip
      w={300}
      label="When you add a source, Chat Aid 'trains' on it. This is how it answers your questions contextually. If you update your source ensure to retrain Chai. This ensures Chat Aid has the latest intel."
    >
      <div className={styles.infoIcon}>
        <SVGIcon name={ICONS_LIST.TOOL_TIP} className={styles.img} />
      </div>
    </Tooltip>
  </Flex>,
  'Added by',
  'Status',
  ' '
];
