import { Text, Flex, TextInput, Select, Button, Checkbox, Tooltip } from '@mantine/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Announcement from 'components/Announcement';
import ViewChanger from '../../Components/ViewChanger';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as LinkWhiteIcon } from 'assets/link-white.svg';
import { SOURCE_TYPES } from 'pages/Integrations/utils';
import data from './data.json';
import styles from './styles.module.scss';

const Header = (props) => {
  const {
    searchValue,
    setSearchValue,
    viewType,
    setViewType,
    user,
    showUpdateModal,
    sourceType,
    setSourceType,
    handleSelectAll,
    pagination,
    disableSelectAll,
    sourcesCount,
    isAllSourcesSelected
  } = props;
  const placeholderText = sourceType === 'channel' ? 'Search for channels' : 'Search for messages';

  function howThisWorksClickHandler() {
    window.open(
      'https://docs.chataid.com/Platforms/slack/message-shortcuts#mark-important',
      '_blank'
    );
  }

  return (
    <div>
      <Announcement
        title='Chai trains on both channels and messages marked as important'
        textCopy='Chai trains on both entire channels and on specific messages on channels marked as important by admins. When it looks for an answer, it prioritizes messages marked as important over regular messages sent on a channel.'
      />
      <Flex mt={27} justify={'space-between'} align='flex-end' className={styles.container}>
        <TextInput
          className={styles.searchInput}
          placeholder={placeholderText}
          leftSection={<SearchIcon />}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <Flex align='flex-end' className={styles.rightPart}>
          <Tooltip
            disabled={!disableSelectAll}
            label='To select sources and take action, wait for them to be processed.'
          >
            <Checkbox
              label='Select all'
              onChange={handleSelectAll}
              color='primaryGreen.3'
              radius={4}
              disabled={disableSelectAll}
              checked={isAllSourcesSelected}
              classNames={{
                root: classNames(styles.checkbox, {
                  [styles.hideSelectAll]: !sourcesCount
                }),
                input: 'cursor-pointer',
                label: classNames(styles.selectAll, { [styles.disabled]: disableSelectAll })
              }}
            />
          </Tooltip>
          <Select
            classNames={{
              input: styles.selectInput,
              option: styles.selectOption,
              dropdown: styles.dropdown,
              label: styles.selectLabel
            }}
            label={<Text className={styles.label}>Source type</Text>}
            defaultValue={SOURCE_TYPES.CHANNELS}
            radius={4}
            width={150}
            value={sourceType}
            searchable={false}
            data={data.dropDownData}
            allowDeselect={false}
            onChange={(value) => setSourceType(value)}
          />
          <ViewChanger
            viewType={viewType}
            setViewType={setViewType}
            user={user}
            showUpdateModal={showUpdateModal}
            hideAddSourceButton={sourceType === SOURCE_TYPES.MESSAGES}
            customWrapperStyles={styles.customWrapperStyles}
            pagination={pagination}
          />

          {sourceType === SOURCE_TYPES.MESSAGES && (
            <Button
              className='primaryBtn '
              rightSection={<LinkWhiteIcon />}
              onClick={howThisWorksClickHandler}
            >
              How this works
            </Button>
          )}
        </Flex>
      </Flex>
    </div>
  );
};

Header.defaultProps = {
  searchValue: '',
  setSearchValue: () => null,
  viewType: 'grid',
  setViewType: () => null,
  user: {},
  showUpdateModal: true,
  sourceType: 'message',
  setSourceType: () => null,
  handleSelectAll: () => null,
  disableSelectAll: false,
  sourcesCount: 0,
  isAllSourcesSelected: false
};

Header.propTypes = {
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
  viewType: PropTypes.string,
  setViewType: PropTypes.func,
  handleSelectAll: PropTypes.func,
  user: PropTypes.object,
  showUpdateModal: PropTypes.bool,
  disableSelectAll: PropTypes.bool,
  sourceType: PropTypes.string,
  setSourceType: PropTypes.func,
  sourcesCount: PropTypes.number,
  isAllSourcesSelected: PropTypes.bool,
  pagination: PropTypes.shape({
    active: PropTypes.number,
    setPage: PropTypes.func
  })
};

export default Header;
