import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Anchor, Button, Center, Flex, Grid } from '@mantine/core';
import { toast } from 'react-toastify';
import { ReactComponent as CreateWikiTeam } from 'assets/widgets/create-wiki-team.svg';
import { ReactComponent as ConfigureWidget } from 'assets/widgets/setup-widget.svg';
import { ReactComponent as EmbedOnWebsite } from 'assets/widgets/embed-on-website.svg';
import { ReactComponent as AddIcon } from 'assets/icons/plus-o.svg';
import { ReactComponent as LearnMore } from 'assets/widgets/learn.svg';
import { ReactComponent as NoLicenseIcon } from 'assets/widgets/no-license.svg';
import { ReactComponent as QuestionIcon } from 'assets/widgets/question.svg';
import { ReactComponent as CallIcon } from 'assets/widgets/call.svg';
import { usePostSlackConnectMutation } from 'redux/services/slack-connect.js';
import styles from './styles.module.scss';

const createSteps = [
  {
    id: 1,
    header: 'Create a wiki for this widget',
    description: (
      <div className={styles.stepDescription}>
        Chai will answer questions using the wiki you select. If you want a new wiki for this
        widget, first <Anchor href='/teams'>create a team</Anchor> and add sources to its wiki.
      </div>
    ),
    image: (
      <div className={styles.illustration}>
        <CreateWikiTeam />
      </div>
    )
  },
  {
    id: 2,
    header: 'Configure widget appearance and capabilities',
    description: (
      <div className={styles.stepDescription}>
        Tailor your chat widget&apos;s appearance to align with your company&apos;s branding
        guidelines.
      </div>
    ),
    image: (
      <div className={styles.illustration}>
        <ConfigureWidget />
      </div>
    )
  },
  {
    id: 3,
    header: 'Enable on your website',
    description: (
      <div className={styles.stepDescription}>
        Set your widget destination and install the widget on your domain in a matter of seconds.
      </div>
    ),
    image: (
      <div className={styles.illustration}>
        <EmbedOnWebsite />
      </div>
    )
  }
];

const learnOptions = [
  {
    id: 1,
    icon: (
      <div className={styles.icon}>
        <NoLicenseIcon />
      </div>
    ),
    text: () => (
      <div className={styles.optionText}>
        No licenses required, no extra fuss! When Chat Aid answers a question, it deducts a question
        from your company-wide quota.
      </div>
    )
  },
  {
    id: 2,
    icon: (
      <div className={styles.icon}>
        <QuestionIcon />
      </div>
    ),
    text: () => (
      <div className={styles.optionText}>
        Have questions? Visit our&nbsp;
        <Anchor href='https://docs.chataid.com/Chat%20Widget/create-a-chat-widget' target='_blank'>
          knowledge base
        </Anchor>
        .
      </div>
    )
  },
  {
    id: 3,
    icon: (
      <div className={styles.icon}>
        <CallIcon />
      </div>
    ),
    text: (handleClick) => (
      <div className={styles.optionText}>
        Talk to your account manager 1:1 on <span onClick={handleClick}>Slack</span> or&nbsp;
        <Anchor href='https://calendly.com/chataid/25min' target='_blank'>
          schedule a call
        </Anchor>
        .
      </div>
    )
  }
];

const EmptyState = ({ onNewWidget }) => {
  const { user } = useSelector((state) => state.user);
  const [postSlackConnect] = usePostSlackConnectMutation();

  const handleSlackConnect = async () => {
    const response = await postSlackConnect({
      emails: [user?.email]
    });
    if (response?.data?.channelId) {
      toast.warning("You're already connected to Sandeep on Slack");
    } else if (response?.data.ok) {
      toast.success('You have been connected to Sandeep on Slack');
    } else {
      toast.error('Something went wrong!');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.paper}>
        <div className={styles.title}>
          Create a chat widget trained on your wiki data for your website
        </div>
        <Grid className={styles.stepGrid}>
          {createSteps.map((step) => (
            <Grid.Col span={{ base: 12, sm: 4 }} key={step.id}>
              <Flex direction='column' justify='center'>
                {step.image}
                <Flex direction='column' align='center'>
                  <div className={styles.stepHeader}>{step.header}</div>
                  {step.description}
                </Flex>
              </Flex>
            </Grid.Col>
          ))}
        </Grid>
        <Center mt={26} mb={30}>
          <Button
            className={styles.primaryBtn}
            leftSection={<AddIcon />}
            radius='sm'
            onClick={onNewWidget}
          >
            Create now
          </Button>
        </Center>
      </div>
      <div className={styles.paper}>
        <div className={styles.learnTitle}>Learn more about how it works:</div>
        <div className={styles.details}>
          <div className={styles.learnIllustration}>
            <LearnMore />
          </div>
          <Flex direction='column' align='center'>
            {learnOptions.map((option) => (
              <div key={option.id} className={styles.optionWrapper}>
                {option.icon}
                {option.text(handleSlackConnect)}
              </div>
            ))}
          </Flex>
        </div>
      </div>
    </div>
  );
};

EmptyState.propTypes = {
  onNewWidget: PropTypes.func.isRequired
};

export default EmptyState;
