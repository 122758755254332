import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Flex, TextInput, Select, Loader, Tooltip, Checkbox } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import integrationsApi, { useDisconnectSourcesMutation } from 'redux/services/integrations';
import SourceGrid from '../../Components/SourceGrid';
import ViewChanger from '../../Components/ViewChanger';
import Table from '../../../../../components/Table';
import EmptyState from '../../Components/EmptyState';
import MultiSelectActions from '../../Components/MultiSelectActions';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import CustomSourcesIcon from 'assets/icons/custom_sources.svg';
import { formatDate } from 'utils/formatters';
import { retrainFailureTooltip, truncateName } from 'pages/Integrations/utils';
import { ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS, VIEW_TYPE, PROVIDERS } from 'utils/constants';
import { SOURCES_STATUS } from '../../Web/constants';
import styles from './styles.module.scss';

const SourcesDataView = (props) => {
  const {
    viewType,
    setViewType,
    sources,
    user,
    isLoading,
    pagination,
    pageCount,
    searchValue,
    setSearchValue,
    debouncedValue,
    metaPayload,
    totalCount,
    useLlamaparser
  } = props;

  const dispatch = useDispatch();
  const isSmallerThanSm = useMediaQuery('(max-width: 767px)');
  const [source, setSource] = useState({
    selectAll: false,
    showRemoveModal: false,
    selected: [],
    fileType: 'all'
  });
  const [totalItems, setTotalItems] = useState([]);
  const selectedSources = totalItems.filter((item) => source.selected.includes(item?.id));
  const [disconnectSources, { isLoading: isDisconnectSourcesLoading }] =
    useDisconnectSourcesMutation();
  const allInProcessing = sources.every((source) => source.status === SOURCES_STATUS.PROCESSING);
  const isProcessing = sources.some((source) => source.status === SOURCES_STATUS.PROCESSING);
  const { teamId } = useParams();
  const filteredSources = sources.filter(
    ({ type }) => source.fileType === 'all' || type === source.fileType
  );
  const tooltipLabels = [
    'Unchecking individual sources when you ‘select all’ is not supported currently. Coming soon.',
    'You cannot select the sources which are under the process. Please wait until they turn active.'
  ];

  const formattedRows = filteredSources.map((list) => {
    return {
      id: list.id,
      title: truncateName(list.name ? decodeURIComponent(list.name) : list.url, 40),
      name: list.name ? list.name : list.url,
      url: list.url,
      type: list.type,
      date: formatDate(list.lastTrainingStartedAt),
      uploadBy: <div className={styles.uploaderName}>{list?.uploadedBy || 'User'}</div>,
      status: list.status,
      admin: list.admin,
      trainingFrequency: list.trainingFrequency,
      lastTrainingStartedAt: list.lastTrainingStartedAt || list.createdAt,
      lastTrainingAttemptedAt: list.lastTrainingAttemptedAt || list.createdAt,
      error: list.error,
      description: list.description,
      retrainError: retrainFailureTooltip(
        list.status,
        list.lastTrainType,
        list.lastTrainingAttemptedAt || list.lastTrainingStartedAt
      ),
      handleOpenSource: () => list.url && window.open(list.url, '_blank'),
      tooltip: list.name.length > 40 && list.name
    };
  });

  const selectValues = sources.reduce(
    (acc, source) => {
      if (source.type && !acc.includes(source.type)) {
        acc.push(source.type);
      }
      return acc;
    },
    ['all']
  );

  async function handleDeleteSources() {
    const payload = {
      provider: PROVIDERS.CUSTOM_SOURCES,
      teamId,
      ...(source.selected.length ? { documentMongoIds: source.selected } : {}),
      ...(!!searchValue && !source.selected.length ? { q: searchValue } : {})
    };
    const response = await disconnectSources(payload);
    if (response?.data?.ok) {
      toast.success(response.data.message);
      setTotalItems((prevItems) => prevItems.filter((item) => !source.selected.includes(item.id)));
      dispatch(integrationsApi.util.invalidateTags(['SourcesByProvider']));
      setSource((prevState) => ({
        ...prevState,
        showRemoveModal: false,
        selected: [],
        selectAll: false
      }));
    }
  }

  function handleSelectAll() {
    setSource((prevState) => ({
      ...prevState,
      selectAll: !prevState.selectAll,
      selected: []
    }));
  }

  function handleCheckboxCta(source) {
    setSource((prevState) => {
      const uncheckSelectedSource = prevState.selected.includes(source.id);

      const updatedSelectedSources = uncheckSelectedSource
        ? prevState.selected.filter((sourceId) => sourceId !== source.id)
        : [...prevState.selected, source.id];

      return {
        ...prevState,
        selected: updatedSelectedSources
      };
    });
  }

  return (
    <>
      <Flex
        mt={27}
        justify='space-between'
        align='flex-end'
        style={{ flexDirection: isSmallerThanSm ? 'column' : 'row' }}
      >
        <TextInput
          className={styles.searchInput}
          placeholder='Search for sources'
          leftSection={<SearchIcon />}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />

        <Flex align='flex-end' justify='space-between'>
          <Tooltip
            disabled={!allInProcessing}
            w={325}
            label='To select sources and take action, wait for them to be processed.'
          >
            <Checkbox
              label='Select all'
              onChange={handleSelectAll}
              checked={source.selectAll}
              color='primaryGreen.3'
              radius={4}
              disabled={allInProcessing}
              classNames={{
                root: classNames(styles.checkbox, {
                  [styles.hideSelectAll]: !sources.length
                }),
                input: 'cursor-pointer',
                label: classNames(styles.selectAll, { [styles.disabled]: allInProcessing })
              }}
            />
          </Tooltip>
          <Select
            label='Filter by format'
            placeholder='Select One'
            defaultValue='all'
            radius={4}
            value={source.fileType}
            searchable={false}
            withScrollArea={false}
            data={selectValues}
            onChange={(value) => {
              setSource((prevState) => ({ ...prevState, fileType: value }));
            }}
            allowDeselect={false}
            classNames={{
              root: styles.selectRoot,
              label: styles.selectLabel,
              dropdown: styles.selectDropdown
            }}
          />

          <ViewChanger
            useLlamaparser={useLlamaparser}
            viewType={viewType}
            setViewType={setViewType}
            user={user}
            hideAddSourceButton={!sources?.length}
            pagination={pagination}
          />
        </Flex>
      </Flex>

      {sources.length ? (
        viewType === VIEW_TYPE.GRID ? (
          <SourceGrid
            items={formattedRows}
            hideRetrainNow={true}
            totalItems={totalItems}
            setTotalItems={setTotalItems}
            user={user}
            hideCreatedAt={true}
            sourceIcon={CustomSourcesIcon}
            pagination={pagination}
            pageCount={pageCount}
            isLoading={isLoading}
            hideManageAutoRetrain={true}
            metaPayload={metaPayload}
            handleCheckboxCta={handleCheckboxCta}
            selectedSources={source.selected}
            showCheckbox
            isAllSourcesSelected={source.selectAll}
            checkboxTooltipLabels={tooltipLabels}
          />
        ) : (
          <Table
            headers={['Source', 'Added on', 'Uploaded by', 'Status', '']}
            rows={formattedRows}
            hideCreatedAt={true}
            pagination={pagination}
            pageCountFromProp={pageCount}
            pageLimitFromProp={ROWS_PER_PAGE_FOR_TRUTO_CONNECTORS}
            isLoading={isLoading}
            sourceIcon={CustomSourcesIcon}
            hideManageAutoRetrain={true}
            totalCount={totalCount}
            metaPayload={metaPayload}
            hideRetrainNow={true}
            disableRowClick
            selectedSources={source.selected}
            handleCheckboxCta={handleCheckboxCta}
            showCheckbox
            isAllSourcesSelected={source.selectAll}
            checkboxTooltipLabels={tooltipLabels}
          />
        )
      ) : isLoading ? (
        <Flex align='center' justify='center' h='55vh'>
          <Loader />
        </Flex>
      ) : debouncedValue ? (
        <EmptyState hideButton text={`No sources matching "${searchValue}" found.`} />
      ) : (
        <EmptyState />
      )}
      {(source.selectAll || source.selected.length > 0) && (
        <MultiSelectActions
          isDisconnectSourcesLoading={isDisconnectSourcesLoading}
          selectAll={source.selectAll}
          isProcessing={isProcessing}
          handleDeleteSources={handleDeleteSources}
          showRemoveSourceModal={source.showRemoveModal}
          selectedSources={selectedSources}
          setShowRemoveSourceModal={(value) =>
            setSource((prevState) => ({ ...prevState, showRemoveModal: value }))
          }
        />
      )}
    </>
  );
};

SourcesDataView.defaultProps = {
  viewType: 'grid',
  setViewType: () => null,
  sources: [],
  isLoading: false,
  useLlamaparser: false
};

SourcesDataView.propTypes = {
  useLlamaparser: PropTypes.bool,
  viewType: PropTypes.string,
  setViewType: PropTypes.func,
  sources: PropTypes.array,
  user: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  pagination: PropTypes.shape({
    active: PropTypes.number,
    setPage: PropTypes.func
  }).isRequired,
  pageCount: PropTypes.PropTypes.number.isRequired,
  searchValue: PropTypes.PropTypes.string.isRequired,
  setSearchValue: PropTypes.PropTypes.func.isRequired,
  debouncedValue: PropTypes.PropTypes.string.isRequired,
  totalCount: PropTypes.number.isRequired,
  metaPayload: PropTypes.shape({
    provider: PropTypes.string,
    params: PropTypes.shape({
      teamId: PropTypes.string,
      page: PropTypes.number,
      limit: PropTypes.number,
      q: PropTypes.string
    })
  }).isRequired
};

export default SourcesDataView;
