import React from 'react';
import { Button, Center, Flex, Image, Popover } from '@mantine/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useProSidebar } from 'react-pro-sidebar';
import { POPUP, usePopupContext } from 'contexts/PopupContext';
import { ReactComponent as InstallIcon } from 'assets/icons/install-icon.svg';
import SlackIcon from 'assets/slack-icon.svg';
import TeamsIcon from 'assets/teams-icon.svg';
import ChromeExtension from 'assets/chrome-extension.svg';
import styles from './styles.module.scss';

const InstallChatAid = () => {
  const { collapsed } = useProSidebar();
  const { openedPopup, setOpenedPopup } = usePopupContext();
  const hideSlackCta = false;
  const hideTeamsCta = false;
  const hideChromeCta = true;

  return (
    <Popover
      position='right-end'
      offset={collapsed ? -10 : -50}
      width={300}
      arrowSize={12}
      arrowPosition='center'
      shadow='md'
      opened={openedPopup === POPUP.INSTALL_CHATAID}
      onChange={() => setOpenedPopup(POPUP.INSTALL_CHATAID)}
    >
      <Popover.Target>
        <Center
          className={classNames(styles.collapsedInstallIcon, { [styles.installIcon]: !collapsed })}
          onClick={() => setOpenedPopup(POPUP.INSTALL_CHATAID)}
          ml={!collapsed && '25'}
        >
          <InstallIcon />
          {!collapsed && <span>Install Chat Aid on</span>}
          {!collapsed && <span className={styles.openIcon}>&gt;</span>}
        </Center>
      </Popover.Target>
      <Popover.Dropdown className={styles.helpPopover}>
        <div className={styles.title}>Install Chat Aid on</div>
        <div className={styles.installCtas}>
          <Flex direction='column' gap={12}>
            {!hideSlackCta && (
              <Button
                leftSection={<Image src={SlackIcon} w={20} h={20} />}
                className={classNames(styles.installBtn)}
                classNames={{ section: styles.buttonIcon }}
                onClick={() =>
                  window.open(
                    'https://slack.com/apps/A04FQN4RN49-albus-chatgpt-workplace-search',
                    '_blank'
                  )
                }
              >
                Slack
              </Button>
            )}
            {!hideTeamsCta && (
              <Button
                leftSection={<Image src={TeamsIcon} w={24} h={24} />}
                className={classNames(styles.installBtn)}
                classNames={{ section: styles.buttonIcon }}
                onClick={() =>
                  window.open(
                    'https://teams.microsoft.com/l/app/1895375a-1617-4fd2-a1ce-fe6908ab86b0?source=app-details-dialog',
                    '_blank'
                  )
                }
              >
                Microsoft Teams
              </Button>
            )}
            {!hideChromeCta && (
              <Button
                leftSection={<Image src={ChromeExtension} w={16} h={16} />}
                className={classNames(styles.installBtn)}
                classNames={{ section: styles.buttonIcon }}
                onClick={() => window.open(import.meta.env.VITE_CHROME_EXTENSION, '_blank')}
              >
                Google Chrome
              </Button>
            )}
          </Flex>
        </div>
      </Popover.Dropdown>
    </Popover>
  );
};

export default InstallChatAid;
