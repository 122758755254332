import { Modal, Title, Flex, Button, Text, Image } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { bool, func } from 'prop-types';
import styles from './slack-connect-modal.module.scss';
import SlackConnectModalImage from 'assets/slack_connect_modal.png';

const SlackConnectModal = ({ opened, setOpened }) => {
  const screen767 = useMediaQuery('(max-width: 767px)');

  return (
    <Modal
      centered
      opened={opened}
      size={630}
      radius={4}
      onClose={() => setOpened(false)}
      title={<Title className={styles.modal_title}>Set up Slack Connect</Title>}
    >
      <Text className={styles.modal_description}>
        Open your Slack and accept the request from Chat Aid on your Slack Connect tab.
      </Text>
      <Image src={SlackConnectModalImage} alt='Slack Connect' className={styles.modal_image} />
      <Flex mt={30} justify='flex-end'>
        <Button
          variant='outline'
          radius={4}
          color='dark'
          onClick={() => {
            setOpened(false);
          }}
          style={{ height: screen767 ? 35 : 42 }}
        >
          Close
        </Button>
        <Button
          className='primaryBtn'
          ml={20}
          onClick={() => {
            const slackConnectUrl = 'slack://app?tab=connect';
            window.location.href = slackConnectUrl;
            setOpened(false);
          }}
        >
          Open Slack
        </Button>
      </Flex>
    </Modal>
  );
};

SlackConnectModal.propTypes = {
  opened: bool.isRequired,
  setOpened: func.isRequired
};

export default SlackConnectModal;
