import { Anchor } from '@mantine/core';
import MondayStep1 from 'assets/connectors/monday-step1.svg';
import MondayStep2 from 'assets/connectors/monday-step2.svg';
import MondayStep3 from 'assets/connectors/monday-step3.svg';
import { NAVBAR_ROUTES } from 'utils/constants';
import { CONNECTOR_CATEGORIES_LIST, SETTINGS_TABS } from './constants';

export const settingsTitleProvider = (tab) => {
  switch (tab) {
    case SETTINGS_TABS.SEARCH_CAPABILITIES:
      return 'Search capabilities';
    case SETTINGS_TABS.COMPANY_ADMIN:
      return 'Company admins';
    case SETTINGS_TABS.PERSONAS:
      return 'Personas';
    case SETTINGS_TABS.CONNECTIONS:
      return 'Connections';
    case SETTINGS_TABS.GENERAL:
      return 'General';
    case SETTINGS_TABS.MY_ACCOUNT:
      return 'My account';
    case SETTINGS_TABS.AUTO_ANSWER:
      return 'Auto-answer';
    case SETTINGS_TABS.AUTOMATIONS:
      return 'Automations';
    case SETTINGS_TABS.CUSTOM_APIS:
      return 'Custom APIs';
  }
};

export const constructConnectionsByCategories = (connections) => {
  const sortPriority = {
    connected: 0,
    active: 1,
    inactive: 2
  };

  // Create a copy of the connections array to avoid modifying the original array
  const sortedConnectors = [...connections].sort((a, b) => {
    // Compare by connection status first
    const statusComparison =
      sortPriority[a.isConnected ? 'connected' : a.isActive ? 'active' : 'inactive'] -
      sortPriority[b.isConnected ? 'connected' : b.isActive ? 'active' : 'inactive'];

    // If status is the same, compare alphabetically
    if (statusComparison === 0) {
      return a.name.localeCompare(b.name);
    }
    return statusComparison;
  });

  const updateConnectorCategories = Object.values(CONNECTOR_CATEGORIES_LIST).map((category) => {
    const filteredConnectors = sortedConnectors.filter(
      (conn) => conn.category === category && conn.connectionSupported
    );
    return {
      name: category,
      count: filteredConnectors.length,
      connectors: filteredConnectors
    };
  });
  return updateConnectorCategories;
};

export const constructOnlyLiveConnectionsByCategories = (connections) => {
  const categories = Object.values(CONNECTOR_CATEGORIES_LIST);
  const updateConnectorCategories = [];
  const liveConnections = connections
    .filter((conn) => conn.isActive)
    .sort((a, b) => a.name.localeCompare(b.name));

  // Iterate through the categories and filter active connectors
  for (const category of categories) {
    const filteredConnectors = liveConnections.filter(
      (conn) => conn.category === category && conn.connectionsSupported
    );

    // Add the category only if it has active connectors
    if (filteredConnectors.length > 0) {
      // Sort live connectors alphabetically within the category
      filteredConnectors.sort((a, b) => a.name.localeCompare(b.name));

      updateConnectorCategories.push({
        name: category,
        count: filteredConnectors.length,
        connectors: filteredConnectors
      });
    }
  }

  // Sort updateConnectorCategories based on the name property alphabetically
  updateConnectorCategories.sort((a, b) => a.name.localeCompare(b.name));

  // Add an "All" category after sorting
  updateConnectorCategories.unshift({
    name: 'All',
    count: liveConnections.length,
    connectors: liveConnections
  });

  return { updateConnectorCategories, liveConnections };
};

/**
 * Formats an array of user data for a dropdown component.
 * @param {Array<Object>} [users=[]] - An array of user objects.
 * @returns {Array<Object>} An array of objects containing `label` and `value` properties.
 */
export const formatUsersDataForDropdown = (users = []) => {
  if (!users?.length) {
    return [];
  }
  return users.map((user) => ({ label: user.name || user.email, value: user._id }));
};

/**
 * Constructs a success message for when admins are added.
 *
 * @param {Array} admins - An array of admins to include in the message.
 * @param {string} admins.label - The name of the admin.
 * @returns {string} - The success message.
 */
export const constructAdminAddedSuccessMessage = (admins = []) => {
  const numberOfAdmins = admins.length;
  if (numberOfAdmins === 0) {
    return '';
  }

  const adminNames = admins.map((admin) => admin.label);
  let message = '';

  if (numberOfAdmins === 1) {
    message = `${adminNames[0]} is now an admin.`;
  } else if (numberOfAdmins === 2) {
    message = `${adminNames[0]} and ${adminNames[1]} are now admins.`;
  } else {
    const otherAdminsCount = numberOfAdmins - 2;
    const otherAdminsText = otherAdminsCount > 1 ? 'others' : 'other';
    message = `${adminNames.slice(0, 2).join(', ')}${
      otherAdminsCount ? `, and ${otherAdminsCount} ${otherAdminsText}` : ''
    } are now admins.`;
  }

  return message;
};

export const MPPageConstructor = (tab) => {
  switch (tab) {
    case 'company':
      return 'Company';
    case 'companyAdmins':
      return 'Company Admins';
    case 'personas':
      return 'Persona';
    default:
      break;
  }
};

export const getInstallSteps = ({ conn, navigate, handleConnect }) => {
  const CONNECTOR_INSTALL_STEPS = {
    mondaycom: [
      {
        title: 'Connect Chat Aid to your monday.com account.'
      },
      {
        title: (
          <>
            Install Chat Aid on your monday.com account.&nbsp;
            <Anchor
              underline='always'
              size='sm'
              c='primaryGreen.3'
              className='font-poppins'
              weight='bold'
              onClick={() =>
                window.open(
                  'https://auth.monday.com/oauth2/authorize?client_id=dc47f8c316ed5055c968b840d8f6b079&response_type=install',
                  '_blank'
                )
              }
            >
              Install
            </Anchor>
            .
          </>
        ),
        image: MondayStep1
      },
      {
        title: (
          <>
            Once you install Chat Aid , you will be redirected to your <b>Manage Apps</b> page. You
            should see Chat Aid under <b>Installed Apps</b> here.
          </>
        ),
        image: MondayStep2
      },
      {
        title: (
          <>
            Connect Chat Aid to your monday.com account.&nbsp;
            <Anchor
              underline='always'
              size='sm'
              c='primaryGreen.3'
              className='font-poppins'
              weight='bold'
              onClick={handleConnect}
            >
              Connect
            </Anchor>
            .
          </>
        ),
        image: MondayStep3
      },
      {
        title: (
          <>
            Once you give access to your monday.com account, you can train Chat Aid on its data. To
            add sources, Go to&nbsp;
            <Anchor
              underline='always'
              size='sm'
              c='primaryGreen.3'
              className='font-poppins'
              weight='bold'
              onClick={() => navigate(NAVBAR_ROUTES.INTEGRATIONS)}
            >
              Wiki
            </Anchor>
            .
          </>
        ),
        image: null
      }
    ]
  };

  return CONNECTOR_INSTALL_STEPS[conn.name] || [];
};
