import { Box, Button, Flex, AppShell, Modal, Select, Text, TextInput } from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useMediaQuery } from '@mantine/hooks';
import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as FilterLogo } from 'assets/icons/filter_ham_lines.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { DATE_DROPDOWN_OPTIONS, DATE_DROPDOWN_VALUES } from 'utils/constants';
import { getDateForSelectedOption } from 'pages/Questions/utils';
import styles from '../../styles.module.scss';

const Filter = ({ filter, setFilter }) => {
  const screen767 = useMediaQuery('(max-width: 767px)');
  const [opened, setOpened] = useState(false);
  // for mobile view, we need to maintain a temp filter object, so that the api is not called on filter change except search.
  const [tempFilter, setTempFilter] = useState(filter);
  const filterApplied = screen767 ? tempFilter : filter;

  const filterUI = () => (
    <>
      <Select
        mr={10}
        label='Date'
        classNames={{ label: styles.label, input: styles.input }}
        placeholder='Select'
        radius={4}
        mb={screen767 ? 12 : 0}
        leftSection={<CalendarIcon />}
        allowDeselect={false}
        searchable={false}
        defaultValue={filterApplied?.dateFilterValue || DATE_DROPDOWN_VALUES.ALL}
        data={DATE_DROPDOWN_OPTIONS}
        onChange={(v) => {
          const updatedFilterObject = {
            ...filterApplied,
            dateFilterValue: v,
            dateRange: { ...getDateForSelectedOption(v) },
            page: 1
          };

          screen767
            ? setTempFilter({ ...updatedFilterObject })
            : setFilter({ ...updatedFilterObject });
        }}
        comboboxProps={{
          position: screen767 ? 'top' : 'bottom',
          middlewares: { flip: false, shift: false }
        }}
      />

      {filterApplied?.dateFilterValue === DATE_DROPDOWN_VALUES.CUSTOM && (
        <DatePickerInput
          type='range'
          valueFormat='MMM DD, YYYY'
          leftSection={<CalendarIcon />}
          leftSectionPointerEvents='none'
          mr={screen767 ? 0 : 12}
          mb={screen767 ? 12 : 0}
          mt='auto'
          w={screen767 ? '100%' : '12.5rem'}
          value={[
            filterApplied?.dateRange?.start && new Date(filterApplied.dateRange.start),
            filterApplied?.dateRange?.end && new Date(filterApplied.dateRange.end)
          ]}
          onChange={(date) => {
            const updatedFilterObject = { ...filterApplied, page: 1 };
            updatedFilterObject['dateRange'] = {
              ...getDateForSelectedOption(DATE_DROPDOWN_VALUES.CUSTOM, {
                start: date[0],
                end: date[1]
              })
            };
            screen767
              ? setTempFilter({
                  ...updatedFilterObject
                })
              : setFilter({
                  ...updatedFilterObject
                });
          }}
          className={
            screen767 ? undefined : classNames('date-picker-style-1', styles.dropdownMobileWidth)
          }
          placeholder='MMM/DD/YYYY'
          clearable={false}
          required={true}
          maxDate={new Date()}
        />
      )}
    </>
  );

  return (
    <Flex align={screen767 ? 'center' : 'end'} justify='space-between' mt={10}>
      {!screen767 && <Flex>{filterUI()}</Flex>}
      <TextInput
        className={styles.searchInput}
        placeholder='Search'
        leftSection={<SearchIcon />}
        value={filter.q}
        onChange={(e) => {
          setFilter({
            ...filter,
            q: e.target.value,
            page: 1
          });
        }}
      />

      {screen767 && (
        <>
          <Flex align='center' onClick={() => setOpened(true)}>
            <FilterLogo />
            <Text c='teal' ml={6} size='sm'>
              Filters(1)
            </Text>
          </Flex>

          <Modal
            opened={opened}
            onClose={() => setOpened(false)}
            className='mobile-modal'
            title='Filters'
          >
            <Flex m={10} direction='column'>
              <Box mb={30}>{filterUI()}</Box>
              <AppShell>
                <AppShell.Footer className={styles.mantineFooter}>
                  <Flex pt={10} pl={20}>
                    <Button
                      mr={10}
                      radius='sm'
                      variant='outline'
                      color='teal'
                      onClick={() => setOpened(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      radius='sm'
                      color='teal'
                      onClick={() => {
                        setOpened(false);
                        // updating the original filter object here, which will trigger the api call.
                        setFilter({ ...tempFilter });
                      }}
                    >
                      Confirm
                    </Button>
                  </Flex>
                </AppShell.Footer>
              </AppShell>
            </Flex>
          </Modal>
        </>
      )}
    </Flex>
  );
};

Filter.propTypes = {
  filter: PropTypes.object,
  setFilter: PropTypes.func,
  handleFilterConfirm: PropTypes.func
};

export default Filter;
