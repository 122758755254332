import PropTypes from 'prop-types';
import { Table as MantineTable, Skeleton } from '@mantine/core';
import Header from './Header';
import WikiRows from './WikiRows';
import QuestionRows from './QuestionRows';
import styles from './styles.module.scss';

const Table = (props) => {
  const {
    headers,
    items,
    showManageResources,
    hideDisconnect,
    actionMenuData,
    itemClickHandler = () => null,
    handleSitemapManageSource = () => null,
    isLoading = false,
    hideRetrainNow = false,
    hideManageAutoRetrain = false,
    messageSourceDeleteModal,
    showManageSourceForWeb,
    showEditName,
    showManageSitemapSources,
    sourceIcon = '',
    metaPayload = {
      provider: '',
      params: {}
    },
    disableRowClick,
    selectedSources = [],
    handleCheckboxCta = () => null,
    showCheckbox = false,
    isAllSourcesSelected = false,
    checkboxTooltipLabels = [],
    rowType = 'wiki'
  } = props;

  let rowsOutput = '';

  if (rowType === 'wiki') {
    rowsOutput = (
      <WikiRows
        actionMenuData={actionMenuData}
        rowValues={items}
        showManageResources={showManageResources}
        hideDisconnect={hideDisconnect}
        itemClickHandler={itemClickHandler}
        handleSitemapManageSource={handleSitemapManageSource}
        isLoading={isLoading}
        hideRetrainNow={hideRetrainNow}
        hideManageAutoRetrain={hideManageAutoRetrain}
        messageSourceDeleteModal={messageSourceDeleteModal}
        showManageSourceForWeb={showManageSourceForWeb}
        showEditName={showEditName}
        showManageSitemapSources={showManageSitemapSources}
        sourceIcon={sourceIcon}
        metaPayload={metaPayload}
        disableRowClick={disableRowClick}
        handleCheckboxCta={handleCheckboxCta}
        showCheckbox={showCheckbox}
        selectedSources={selectedSources}
        isAllSourcesSelected={isAllSourcesSelected}
        checkboxTooltipLabels={checkboxTooltipLabels}
      />
    );
  } else if (rowType === 'question') {
    rowsOutput = (
      <QuestionRows
        rowValues={items}
        itemClickHandler={itemClickHandler}
        disableRowClick={disableRowClick}
        handleCheckboxCta={handleCheckboxCta}
        showCheckbox={showCheckbox}
        selectedSources={selectedSources}
        isAllSourcesSelected={isAllSourcesSelected}
        checkboxTooltipLabels={checkboxTooltipLabels}
      />
    );
  }

  return (
    <div className={styles.tableContainer}>
      <MantineTable className={styles.documentsListTable}>
        <Header headers={headers} />
        {isLoading ? (
          <MantineTable.Tbody>
            <MantineTable.Tr>
              {headers.map((i) => (
                <MantineTable.Td key={i}>
                  <Skeleton height={30} />
                </MantineTable.Td>
              ))}
            </MantineTable.Tr>
          </MantineTable.Tbody>
        ) : (
          rowsOutput
        )}
      </MantineTable>
    </div>
  );
};

Table.propTypes = {
  items: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
  hideDisconnect: PropTypes.bool,
  showManageResources: PropTypes.bool,
  actionMenuData: PropTypes.func,
  itemClickHandler: PropTypes.func,
  handleSitemapManageSource: PropTypes.func,
  isLoading: PropTypes.bool,
  hideRetrainNow: PropTypes.bool,
  hideManageAutoRetrain: PropTypes.bool,
  messageSourceDeleteModal: PropTypes.bool,
  showManageSourceForWeb: PropTypes.bool,
  showEditName: PropTypes.bool,
  showManageSitemapSources: PropTypes.bool,
  sourceIcon: PropTypes.string,
  metaPayload: PropTypes.shape({
    provider: PropTypes.string,
    params: PropTypes.shape({
      teamId: PropTypes.string,
      page: PropTypes.number,
      limit: PropTypes.number,
      q: PropTypes.string
    })
  }),
  disableRowClick: PropTypes.bool,
  selectedSources: PropTypes.array,
  handleCheckboxCta: PropTypes.func,
  showCheckbox: PropTypes.bool,
  isAllSourcesSelected: PropTypes.bool,
  checkboxTooltipLabels: PropTypes.array
};

export default Table;
