import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { AppShell, Group, Avatar, Title, Button, Tooltip } from '@mantine/core';
import CompanySwitch from '../CompanySwitch';
import RemainingQuestionsBanner from './RemainingQuestionsBanner';
import FallbackImage from 'assets/company-fallback.svg';
import { ReactComponent as PaymentIcon } from 'assets/icons/payment-header.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings-header.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/error_warning.svg';
import { NAVBAR_ROUTES } from 'utils/constants';
import styles from './layout.module.scss';

const Header = (props) => {
  const { user, pageTitle, companies, handleCompanySwitch, isOnBoarding, planInfo } = props;
  const navigate = useNavigate();

  return (
    <AppShell>
      <AppShell.Header className={styles.mantineHeader}>
        <div className={styles.headerContainer}>
          <Title order={4} data-testid='header-title'>
            {pageTitle}
          </Title>
          <Group
            justify='apart'
            gap={18}
            data-testid='header-page'
            className={classNames(styles.teamName, { [styles.hidden]: isOnBoarding })}
          >
            <RemainingQuestionsBanner user={user} ErrorIcon={<ErrorIcon />} />

            {!planInfo?.isTrialExpired && (
              <Tooltip label='Settings' position='bottom' multiline={false}>
                <Button
                  onClick={() => navigate(NAVBAR_ROUTES.SETTINGS)}
                  className={styles.headerPageIcon}
                >
                  <SettingsIcon />
                </Button>
              </Tooltip>
            )}

            {user?.isAdmin && !planInfo?.isTrialExpired && (
              <Tooltip label='Billing' position='bottom' multiline={false}>
                <Button
                  onClick={() => navigate(NAVBAR_ROUTES.BILLING)}
                  className={styles.headerPageIcon}
                >
                  <PaymentIcon />
                </Button>
              </Tooltip>
            )}

            {companies?.length < 2 ? (
              <>
                <Avatar
                  classNames={{ root: styles.headerAvatar, image: styles.avatarImage }}
                  size={32}
                  src={user?.team?.photo || FallbackImage}
                />
                <Title className={styles.title}>{user?.team?.name || 'Your Team'}</Title>
              </>
            ) : (
              <CompanySwitch
                companies={companies}
                user={user}
                handleCompanySwitch={handleCompanySwitch}
                popoverPosition='bottom-end'
              />
            )}
          </Group>
        </div>
      </AppShell.Header>
    </AppShell>
  );
};

Header.default = {
  companies: [],
  handleCompanySwitch: () => null,
  isOnBoarding: false
};

Header.propTypes = {
  user: PropTypes.object.isRequired,
  pageTitle: PropTypes.any,
  companies: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, logo: PropTypes.string, id: PropTypes.string })
  ),
  handleCompanySwitch: PropTypes.func,
  isOnBoarding: PropTypes.bool,
  planInfo: PropTypes.object.isRequired
};

export default Header;
