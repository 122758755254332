import PropTypes from 'prop-types';
import { Pagination as MantinePagination, Flex, Text } from '@mantine/core';
import { maybePluralize } from 'utils/utilities';
import { DOCS_PER_PAGE } from 'utils/constants';
import styles from './styles.module.scss';

const Pagination = (props) => {
  const { page = 1, setPage, totalCount } = props;
  const pageCount = Math.ceil(totalCount / DOCS_PER_PAGE);
  const lastItems = totalCount - (pageCount - 1) * DOCS_PER_PAGE;

  return (
    <div className={styles.tableWrapper}>
      {props.children}
      <Flex className={styles.paginationWrapper}>
        <MantinePagination total={pageCount} onChange={setPage} value={page} />
        <Text>
          {page === pageCount ? lastItems : DOCS_PER_PAGE}
          &nbsp;of {totalCount} {maybePluralize(totalCount, 'result')}
        </Text>
      </Flex>
    </div>
  );
};

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired
};

export default Pagination;
