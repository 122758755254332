import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Tabs } from '@mantine/core';
import Layout from 'components/Layout';
import Tab from './Components/Tab';
import { useFetchEscalationSummaryQuery } from 'redux/services/questions';
import DeleteWarningModal from 'components/Modals/DeleteWarningModal';
import { eventTracking } from 'components/EventAnalyticsProvider/utils';
import { startEscalationsTutorial } from './Tutorial';
import useShowTutorial from 'custom_hooks/useShowTutorial';
import { NAVBAR_ROUTES, QUESTIONS_PAGE_TABS } from 'utils/constants';
import styles from './styles.module.scss';

const Questions = () => {
  const { data: { isAppUpdated } = {} } = useFetchEscalationSummaryQuery();
  const [questionCount, setQuestionCount] = useState({
    escalatedCount: 0,
    nonEscalatedCount: 0
  });
  const [showReinstallModal, setShowReinstallModal] = useState(false);
  const [activeTab, setActiveTab] = useState(QUESTIONS_PAGE_TABS.NEEDS_ATTENTION);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const questionId = searchParams.get('questionId');
  const { user } = useSelector((state) => state.user);
  const isSlackPlatform = user?.platform === 'slack';
  const tab = window.location.hash.replace('#', '');

  useShowTutorial(startEscalationsTutorial);

  useEffect(() => {
    if (user._id) {
      eventTracking('User opened dashboard', user, 'Questions');
      if (!user.isAdmin) {
        navigate('/');
      }
    }
  }, [user]);

  useEffect(() => {
    if (isAppUpdated === false) {
      setShowReinstallModal(true);
    }
  }, [isAppUpdated]);

  useEffect(() => {
    if (tab) {
      setActiveTab(tab);
    }
  }, [tab]);

  return (
    <Layout pageTitle='Questions'>
      <DeleteWarningModal
        open={showReinstallModal}
        onClose={() => {
          setShowReinstallModal(false);
        }}
        header='Missing important updates'
        handleCancel={() => {
          setShowReinstallModal(false);
        }}
        handleDelete={() =>
          window.open(`${import.meta.env.VITE_API_URL}/install?redirect=/questions`, '_self')
        }
        removeText='Update now'
        buttonColor='teal'
      >
        <p className={styles.text}>
          Update Chai to enable escalations for your team.
          <br />
          With escalations, your teammates can get help from admins when Chai is unable to help
          them. This ensures your team always finds the answer they’re looking for.
        </p>
      </DeleteWarningModal>
      {isSlackPlatform ? (
        <Tabs
          styles={{
            tabsList: {
              flexWrap: 'nowrap',
              justifyContent: 'space-between'
            },
            tab: {
              width: '50%'
            }
          }}
          color='teal'
          id='escaltaions'
          value={activeTab}
          onChange={(tab) => {
            setActiveTab(tab);
            navigate(NAVBAR_ROUTES.QUESTIONS + '#' + tab);
          }}
        >
          <Tabs.List>
            <Tabs.Tab className={styles.tabLabel} value={QUESTIONS_PAGE_TABS.NEEDS_ATTENTION}>
              Needs attention ({questionCount.escalatedCount})
            </Tabs.Tab>
            <Tabs.Tab className={styles.tabLabel} value={QUESTIONS_PAGE_TABS.EVERYTHING_ELSE}>
              Everything else ({questionCount.nonEscalatedCount})
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value={QUESTIONS_PAGE_TABS.NEEDS_ATTENTION}>
            <Tab
              setQuestionCount={setQuestionCount}
              tab={QUESTIONS_PAGE_TABS.NEEDS_ATTENTION}
              defaultSelectedQuestion={questionId}
            />
          </Tabs.Panel>
          <Tabs.Panel value={QUESTIONS_PAGE_TABS.EVERYTHING_ELSE}>
            <Tab setQuestionCount={setQuestionCount} tab={QUESTIONS_PAGE_TABS.EVERYTHING_ELSE} />
          </Tabs.Panel>
        </Tabs>
      ) : (
        <Tab setQuestionCount={setQuestionCount} tab={QUESTIONS_PAGE_TABS.EVERYTHING_ELSE} />
      )}
    </Layout>
  );
};

export default Questions;
