import { Flex, Image, List, Text, Title } from '@mantine/core';
import { ReactComponent as TickIcon } from 'assets/icons/green-tick.svg';
import BoostProductivityIllustration from 'assets/actions/productivity-illustration.svg';
import styles from './styles.module.scss';

const InformationPanel = () => {
  const infoItems = [
    `Discussing a bug on Slack? Ask Chai to create a ticket for you right inside Slack!`,
    `Found a new sales opportunity? Ask Chai to update your CRM and boom - focus on making $!`,
    `Need to block a calendar? Ask Chai and he'll schedule a meeting for you.`
  ];

  return (
    <div className={styles.panelContainer}>
      <div className={styles.infoContainer} data-testid='informationpanel-text'>
        <Title className={styles.title}>Execute all your actions from one place</Title>
        <Flex direction='column' align='flex-start' gap={20}>
          {infoItems.map((item, index) => (
            <List key={index} icon={<TickIcon />} classNames={{ itemWrapper: styles.ListItems }}>
              <List.Item>
                <Text className={styles.infoText}>{item}</Text>
              </List.Item>
            </List>
          ))}
        </Flex>
      </div>

      <div className={styles.illustrationContainer} data-testid='informationpanel-image'>
        <Image src={BoostProductivityIllustration} />
      </div>
    </div>
  );
};

export default InformationPanel;
